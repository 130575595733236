import {keyframes} from "styled-components";
import styled from "styled-components";

const blinkBlue = keyframes`
0% {
background-color: #24E0FF; 
}
50% {
background-color: #008baa; 
box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 3px 1px, inset #006 0 -1px 4px, #24E0FF 0 1px 0; 
}
100% {
background-color: #24E0FF; 
}
`;

const blinkRed = keyframes`
0% {
background-color: #F00; 
}
50% {
background-color: #A00;
box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 3px 1px, inset #441313 0 -1px 4px, rgba(255, 0, 0, 0.5) 0 1px 0;
}
100% {
background-color: #F00;
}
`;

export const Indicator = styled.span`
display: block;
width: 12px;
height: 12px;
border-radius: 50%;
margin-right: 8px;

&.available, &.online {
background-color: #1ab55d;
box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 3px 1px, inset #304701 0 -1px 4px, #89FF00 0 1px 6px;
}

&.occupied {
background-color: #24E0FF;
box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 3px 1px, inset #006 0 -1px 4px, #3F8CFF 0 1px 7px;
animation: ${blinkBlue} 1s infinite;
}
  
&.reserved {
background-color: #24E0FF;
box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 3px 1px, inset #006 0 -1px 4px, #3F8CFF 0 1px 7px;
}

&.faulted, &.unavailable, &.offline {
background-color: #F00;
box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 3px 1px, inset #441313 0 -1px 4px, rgba(255, 0, 0, 0.5) 0 1px 6px;
animation: ${blinkRed} 0.5s infinite;
}
`;

export const validate = (values, languages) => {
  const err = {
    name: false,
    location_lat: false,
    location_lng: false,
    defaultLanguage: false,
    languages: [...languages.map(el => (
      {
        lang: el,
        name: false,
        address: false,
        default: false
      }
      ))]
  };

  for (let lang of values.languages) {
    if (!lang.name) {
      err.languages = [...err.languages.map(error => error.lang === lang.lang ? {...error, name: 'required'} : {...error})]
    }
    if (!lang.address) {
      err.languages = [...err.languages.map(error => error.lang === lang.lang ? {...error, address: 'required'} : {...error})]
    }
  }
  if (!values.name) {
    err.name = 'required'
  }
  if (!values.location_lat) {
    err.location_lat = 'required'
  }
  if (!values.location_lng) {
    err.location_lng = 'required'
  }
  if (!values.defaultLanguage) {
    err.defaultLanguage = 'required'
  }
  /*if (!values.email) {
      err.email = 'required'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      err.email = 'invalid email'
  }
  if (!values.phoneNumberCountryCode) {
      err.phoneNumberCountryCode = 'required'
  }
  if (!values.phoneNumberAreaCode) {
      err.phoneNumberAreaCode = 'required'
  }
  if (!values.phoneNumberNumber) {
      err.phoneNumberNumber = 'required'
  }
  if (values.addrZipCode > 7) {
      err.addrZipCode = 'max length 8'
  }*/
  return err
};

import React from 'react';

import {
  Indicator
} from './style.js';

function StatusIndicator(props) {
  return (
    <Indicator {...props}/>
  );
}

export default StatusIndicator

import TYPES from './actionTypes';
import { languagesAPI } from '../../api/languages';

export const fetchLanguages = () => {
  return async (dispatch) => {
    dispatch({type: TYPES.FETCH_LANGUAGES_PENDING});
    return languagesAPI()
      .then((result) => {
        dispatch({
          type: TYPES.FETCH_LANGUAGES_SUCCESS,
          payload: result.data.response
        });
      }).catch((error) => {
        dispatch({type: TYPES.FETCH_LANGUAGES_FAILURE});
        if (error.response) {
          throw error.response.data;
        }
      });
  };
};

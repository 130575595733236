import styled, {keyframes} from 'styled-components'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Wrapper = styled.div`
display: flex;
position:fixed;
top:0;
left:0;
width:100%;
height:100%;
justify-content: center;
align-items: center;
background: rgba(0,0,0,.5);
z-index: 999;

> .loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #FFB800;
  width: 30px;
  height: 30px;
  animation: ${rotate} 2s linear infinite;
  }

&.local {
position: absolute;
width:100%!important;
border-radius: 10px;
}
`;

const isLoggedIn = !!window.localStorage.getItem('JWT_TOKEN');

export default {
    app: {
        isLoading: false,
    },
    auth: {
        isLoggedIn,
    },
    entities: {
      languages: [],
      markerPosition: {
        lat: null,
        lng: null,
      },
      chargePointGroups: [],
      chargePointGroup: {},
      chargePoint: {
        isWaitingResponse: false,
        messageResponse: null
      }
    }
};

import styled from 'styled-components'

export const AppContainer = styled.div`
height: 100%;
min-height: 100vh;
min-width: 320px;
width: 100%;
`;

export const AppMain = styled.main`
position: relative;
`;

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'
import appReducer from './appReducer';
import authReducer from './authReducer';
import entitiesReducer from './entitiesReducer';

export default (history) => combineReducers({
    app: appReducer,
    router: connectRouter(history),
    auth: authReducer,
    entities: entitiesReducer
})

import {createStore, applyMiddleware} from 'redux';
import {routerMiddleware} from 'connected-react-router'
import thunk from 'redux-thunk';
import { createBrowserHistory as createHistory } from 'history'
import {composeWithDevTools} from 'redux-devtools-extension'
import createRootReducer from './reducers/rootReducer';
import cartMiddleware from './middleware/session';
import initialState from './initialState';

export const history = createHistory();

const middleware = [
    thunk,
    routerMiddleware(history),
    cartMiddleware
];

const persistedState = initialState;

const composedEnhancers = composeWithDevTools(applyMiddleware(...middleware));

export default createStore(
    createRootReducer(history),
    persistedState,
    composedEnhancers
);

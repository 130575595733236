import { connect } from 'react-redux';

import { logInUser, fetchUsername } from '../../store/actions/authActions';

import SignIn from './SignIn';

const mapDispatchToProps = (dispatch) => {
	return {
		signIn: (credentials) => {
			return dispatch(logInUser(credentials));
		},
		getUsername: () => {
			return dispatch(fetchUsername());
		}
	};
};

export default connect(
	null,
	mapDispatchToProps
)(SignIn);

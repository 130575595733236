import styled from 'styled-components'

export const MainFieldBox = styled.div`
position: relative;
display: flex;
flex-direction: row;
align-items: center;
justify-content: flex-start;
margin-top: 15px;
&:first-child {
    margin-top: 0;
}

&.radio {
justify-content: flex-end;
}

& [type=file] {
position: absolute;
top: -9999px;
}
`;

export const Uploader = styled.div`
cursor: pointer;
background-color: #ffffff;
min-width: ${props => props.width};
width: 70%;
height: 30px;
padding: 0 10px;
border: solid 1px #c8c8c8;
display: flex;
align-items: center;
&.active {
border: solid 1px #fac715;
}
&.invalid {
border: solid 1px #fb397e;
}
&:focus {
outline-color: #fac715;
}
&.disabled {
border: solid 1px #c8c8c8;
}
& > div {
position: relative;
height: 25px;
margin-right: 10px;


&:hover {
&:before, :after {
display: block;
position: absolute;
content: ' ';
top: 50%;
left: 50%;
width: 50%;
max-width: 12px;
transform: translate(-50%, -50%) rotate(45deg);
height: 1px;
background: #ffffff;
border-radius: .5px;
z-index: 1;
}

&:after {
transform: translate(-50%, -50%) rotate(-45deg);
}

& > img {
filter: brightness(0.5);
}
}

& > img {
height: 100%;
width: auto;
transition: filter .3s; 
}

}
`;

export const MainInput = styled.input`
background-color: inherit;
min-width: ${props => props.width};
width: 70%;
height: 30px;
border: solid 1px #c8c8c8;
font-size: 14px;
text-align: ${props => props.textAlign};
color: #4a4a4a;
line-height: 1;
vertical-align: middle;
padding: 0 10px;
&.active {
    border: solid 1px #fac715;
}
&.invalid {
border: solid 1px #fb397e;
}

&:focus {
outline-color: #fac715;
}

&.disabled {
border: solid 1px #c8c8c8;
}

&.borderBottom {
border: none;
border-bottom: solid 1px #c8c8c8!important;

&.active {
border: none;
border-bottom: solid 1px #0091e6!important;

&.no_activated {
border: none!important;
border-bottom: 1px solid #c8c8c8!important;
}
}

&.invalid {
border: none;
border-bottom: solid 1px #fb397e!important;
}

&.disabled {
border-bottom: solid 1px #c8c8c8!important;
}

&.borderBottom:focus {
border: none;
border-bottom: solid 1px #0091e6!important;
}

&.borderBottom.no_activated:focus {
border: none;
border-bottom: solid 1px #CCCCCC!important;
}

&.disabled:focus  {
border-bottom: solid 1px #c8c8c8!important;
}
}

&.radio:checked, &.radio:not(:checked) {
display: none;
}
  
&.radio + label {
position: relative;
cursor: pointer;
}

&.invalid.radio + label:before {
 border: 1px solid #fb397e;
}

&.radio + label:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-100%, -50%);
  width: 10px;
  height: 10px;
  border: 1px solid #c8c8c8;
  border-radius: 50%;
  background-color: #FFF;
}
&.radio + label:after {
  content: '';
  position: absolute;
  top: 50%;
  left: -2px;
  transform: translate(-100%, -50%);
  width: 6px;
  height: 6px;
  border-radius: 50%;
  border: 1px solid #fac715;
  background-color: #fac715;
  opacity: 0;
  transition: opacity .3s;
}
&.radio:checked + label:after {
  opacity: 1;
}

@media (max-width: 400px) {
min-width: 204px;
}
`;
export const LabelBefore = styled.span`
position: absolute;
top: 50%;
left: 10px;
transform: translate(0, -50%);
color: #80807f;

&.active {
color: #4a4a4a;
}
`;
export const LabelForInput = styled.label`
min-width: 100px;
width: 30%;
font-size: 12px;
line-height: 1;
padding: 0 10px;
font-weight: 100;
color: #80807f;
//text-transform: uppercase;
`;
export const Err = styled.span`
font-size: 12px;
line-height: 1;
font-weight: 100;
color: #fb397e;
position: absolute;
bottom: -10px;
left: 0;
`;

import axios from 'axios';
import { axiosInstance } from './axiosInstance';
import qs from 'qs';

export function loginAPI (credentials) {
	const data = qs.stringify(credentials);
	return (
		axios({
			method: 'post',
			url: '/api/v1/users/me',
			baseURL: process.env.REACT_APP_URL && process.env.REACT_APP_URL,
			data,
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
		})
	);
}

export function fetchUsernameAPI () {
	return (
		axiosInstance.get('/api/v1/users/me')
	);
}
import React, {Component} from 'react'
import './BlockInteractiveHover.css';

class BlockInteractiveHover extends Component {
  constructor(props) {
    super(props);
    this.state = {
      position: {
        top: 0,
        left: 0,
      }
    };
  }

  handleChangePosition = (e) => {
    let target = e.target;
    const {id} = this.props;
    let correctTarget;
    if (target.id === id.toString()){
      correctTarget = target
    } else if (target.offsetParent.id === id.toString()){
      correctTarget = target.offsetParent
    } else {
      correctTarget = target.offsetParent.offsetParent
    }

    let rect = correctTarget.getBoundingClientRect();
    let relX = e.pageX - (rect.left + document.body.scrollLeft);
    let relY = e.pageY - (rect.top + document.body.scrollTop);

    this.setState({
      position: {
        top: relY,
        left: relX,
      }
    });
  };

  render() {
    const {position} = this.state;
    const {children, id} = this.props;

    return <div className='card'
                id={id}
                onMouseEnter={this.handleChangePosition}
                onMouseOut={this.handleChangePosition}
    >
      {
        children
      }
      <span className='background' style={{...position}}/>
    </div>
  }
}

export default BlockInteractiveHover

import TYPES from '../actions/actionTypes'
import initialState from '../initialState';

export default function entitiesReducer(state = initialState, action) {
  const {
    type,
    payload
  } = action;

  switch (type) {
    case TYPES.FETCH_LANGUAGES_SUCCESS:
      return {
        ...state,
        languages: payload
      };
    case TYPES.CHANGE_MARKER_POSITION:
      return {
        ...state,
        markerPosition: payload
      };
    case TYPES.FETCH_CHARGE_POINT_GROUPS_SUCCESS:
      return {
        ...state,
        chargePointGroups: payload
      };
    case TYPES.CLEAR_CHARGE_POINT_GROUPS:
      return {
        ...state,
        chargePointGroups: []
      };
    case TYPES.FETCH_CHARGE_POINT_GROUP_SUCCESS:
      return {
        ...state,
        chargePointGroup: payload
      };
    case TYPES.CLEAR_CHARGE_POINT_GROUP:
      return {
        ...state,
        chargePointGroup: {}
      };
    case TYPES.FETCH_CHARGE_POINT_ACTION_PENDING:
      return {
        ...state,
        chargePoint: {
          isWaitingResponse: true,
          messageResponse: null
        }
      };
    case TYPES.CLEAR_CHARGE_POINT:
      return {
        ...state,
        chargePoint: {
          isWaitingResponse: false,
          messageResponse: null
        }
      };
    case TYPES.CLEAR_CHARGE_POINT_ACTION:
      return {
        ...state,
        chargePoint: {
          ...state.chargePoint,
          isWaitingResponse: false,
          messageResponse: null
        }
      };
    case TYPES.FETCH_CHARGE_POINT_ACTION_SUCCESS:
    case TYPES.FETCH_CHARGE_POINT_ACTION_FAILURE:
      return {
        ...state,
        chargePoint: {
          isWaitingResponse: false,
          messageResponse: payload
        }
      };
    case TYPES.FETCH_CHARGE_POINT_SUCCESS:
      return {
        ...state,
        chargePoint: {
          ...state.chargePoint,
          ...payload
        }
      };
    default:
      return state;
  }
}

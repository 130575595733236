import { axiosInstance } from './axiosInstance';

export function chargePointGroupsAPI (id) {
  if(id){
    return (
      axiosInstance.get(`/api/v1/charge-point-groups/${id}`)
    );
  } else {
    return (
      axiosInstance.get(`/api/v1/charge-point-groups`)
    );
  }
}

export function postChargePointGroupAPI (data) {
    return (
      axiosInstance.post(`/api/v1/charge-point-groups/`, data)
    );
}

export function updateChargePointGroupAPI (data, id) {
  return (
    axiosInstance.patch(`/api/v1/charge-point-groups/${id}`, data)
  );
}

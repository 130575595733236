import { connect } from 'react-redux';

import App from './App';

const mapStateToProps = (state) => ({
    routing: state.routing,
    //pathname: state.routing.location.pathname,
    role: state.auth.role,
    isLoggedIn: state.auth.isLoggedIn,
    isLoading: state.app.isLoading,
});

export default connect(mapStateToProps, null)(App);

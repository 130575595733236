import {connect} from 'react-redux';

import {changeMarkerPosition} from '../../store/actions/markerPositionAction';
import {fetchLanguages} from '../../store/actions/languagesActions';

import CreateNewLocation from './CreateNewLocation';
import {clearChargePointGroup, fetchChargePointGroups} from "../../store/actions/chargePointGroupsActions";

const mapStateToProps = (state) => ({
  isLoading: state.app.isLoading,
  lang: state.entities.languages,
  chargePointGroup: state.entities.chargePointGroup,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getLanguages: () => {
      return dispatch(fetchLanguages());
    },
    fetchLocationOnApi: (id) => {
      dispatch(fetchChargePointGroups(id))
    },
    clearCPG: () => {
      return dispatch(clearChargePointGroup());
    },
    changeMarkerPosition: (data) => {
      dispatch(changeMarkerPosition(data))
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateNewLocation);

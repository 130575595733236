import TYPES from './actionTypes';
import {chargePointGroupsAPI} from '../../api/chargePointGroups';

export const fetchChargePointGroups = (id) => {
  return async (dispatch) => {
    if (id) {
      dispatch({type: TYPES.FETCH_CHARGE_POINT_GROUP_PENDING});
      return chargePointGroupsAPI(id)
        .then((result) => {
          dispatch({
            type: TYPES.FETCH_CHARGE_POINT_GROUP_SUCCESS,
            payload: result.data
          });
        }).catch((error) => {
          dispatch({type: TYPES.FETCH_CHARGE_POINT_GROUP_FAILURE});
          if (error.response) {
            throw error.response.data;
          }
        });
    } else {
      dispatch({type: TYPES.FETCH_CHARGE_POINT_GROUPS_PENDING});
      return chargePointGroupsAPI(id)
        .then((result) => {
          dispatch({
            type: TYPES.FETCH_CHARGE_POINT_GROUPS_SUCCESS,
            payload: result.data.response
          });
        }).catch((error) => {
          dispatch({type: TYPES.FETCH_CHARGE_POINT_GROUPS_FAILURE});
          if (error.response) {
            throw error.response.data;
          }
        });
    }
  };
};

export const clearChargePointGroup = () => {
  return async (dispatch) => {
      dispatch({type: TYPES.CLEAR_CHARGE_POINT_GROUP});
  };
};

export const clearChargePointGroups = () => {
  return async (dispatch) => {
    dispatch({type: TYPES.CLEAR_CHARGE_POINT_GROUPS});
  };
};

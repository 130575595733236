import React from 'react'
import {MainInput, Err, LabelForInput, MainFieldBox, LabelBefore, Uploader} from './style'

const MainField = ({label, className, placeholder, autoComplete, textAlign, width, style, labelBefore, error, inputRef, children, ...inputProps}) => {
    if (inputProps.type === 'file') {
      return (
        <MainFieldBox style={style}
                      onClick={inputProps.triggerInput}
        >
          <MainInput
            {...inputProps}
            innerRef={inputRef}
            width={width || '230px'}
          />
          <Uploader className={`${className} ${children && children.length ? 'active' : ''}`}>{children}</Uploader>
          {
            label ?
              <LabelForInput>
                {label}
              </LabelForInput> : null
          }
          {error && <Err>{error}</Err>}
        </MainFieldBox>
      )
    } else {
      return (
        <MainFieldBox style={style}
                      className={inputProps.type === 'radio' || inputProps.type === 'checkbox' ? 'radio' : ''}
        >
          <MainInput
            {...inputProps}
            placeholder={placeholder || ''}
            className={`${className} ${inputProps.value ? 'active' : ''}`}
            autoComplete={autoComplete || 'off'}
            textAlign={textAlign || 'left'}
            width={width || '230px'}
            id={inputProps.type === 'radio' || inputProps.type === 'checkbox' ? inputProps.id : ''}
          />
          {labelBefore ?
            <LabelBefore
              className={`${inputProps.value ? 'active' : ''} `}
            >
              {labelBefore}
            </LabelBefore> : null}
          {
            label ?
              <LabelForInput
                htmlFor={inputProps.type === 'radio' || inputProps.type === 'checkbox' ? inputProps.id : ''}
                onClick={inputProps.type === 'radio' || inputProps.type === 'checkbox' ? inputProps.onFocus : () => {
                }}
              >
                {label}
              </LabelForInput> : null
          }
          {error && <Err>{error}</Err>}
        </MainFieldBox>
      )
    }
};

export default MainField

import styled from "styled-components";

export const ButtonImg = styled.button`
border-radius: 50%;
width: 28px;
height: 28px;
    
> img {
width: 28px;
height: 28px;
}

&:disabled {
cursor: default;
> img {
filter: grayscale(1);
}
}
`;

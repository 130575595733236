import styled from 'styled-components'

export const Container = styled.div`
display: flex;
align-items: flex-start;
transition: all 0.3s ease-out, right 0.3s ease-out;

@media (max-width: 767px) {
flex-direction: column;
font-size: 14px;
}
`;

export const StationsList = styled.ul`
width: 100%;
margin-top: 8px;
padding: 0 8px 8px;
height: calc(100vh - 16px);
overflow-x: auto;

-ms-overflow-style: none;
scrollbar-width: none;

&::-webkit-scrollbar { 
display: none;
}
 
> .header {
position: sticky;
top: 0px;
z-index: 2;
margin-top: 0px;
> .Btn {
flex: 0;
}
}
`;

export const ChargePointList = styled.ul`
width: 100%;
margin-left: auto;
transform: translateX(-105%);
height: 0;
transition: transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
opacity: 0;

> li {
margin-top: 0px;
&.header {
padding: 16px;
}
}

&.active {
height: auto;
transform: translateX(0);
opacity: 1;
> li {
margin-top: 10px;
cursor: default;
&.cp {
padding: 0 50px 0 16px;
}
}
}

@media (max-width: 767px) {
width: 100%;
&.active {
flex-wrap: wrap;
display: flex;
justify-content: space-between;
& > .header {
width: 100%;
> *:not(:first-child):not(.Btn) {
display: none;
}
}
> li {
&.cp {
width: 49%
padding: 8px;
> span {
max-width: 100%;
margin-bottom: 5px;
}
}
}
}
}
`;

export const ListItem = styled.li`
position: relative;
overflow: hidden;
padding: 0 66px 0 32px;
display: flex;
align-items: center;
margin-top: 10px;
border-radius: 10px;
box-shadow: 0 0 7.84px 0.16px rgba(21, 10, 5, 0.11);
background-color: #ffffff;
color: #3a3a3a;

> span {
flex: 1;
padding: 8px 5px;

&.center {
text-align: center;
}
}

&.header {
padding: 16px 32px;
text-transform: capitalize;
font-weight: 100;
background: #3a3a3a;
color: #fff;

> .Btn {
flex: 0;
}
}

&.withCP {
cursor: pointer;
transition: color .3s;

&:hover {
color: #000000;
}
}

&.withCP.active {
color: orange;
}

&.withCP:before {
position: absolute;
top: 50%;
left: 0;
transform: translate(100%,-50%) rotate(45deg);
display: block;
content: '';
width: 8px;
height: 8px;
border-right: 1px solid grey;
border-bottom: 1px solid grey;
transition: transform .4s, border-color .4s;
}

&:hover {
&.withCP:before{
border-color: orange;
transform: translate(100%,-25%) rotate(45deg);
}
}

&.withCP.active:before {
transform: translate(100%,-50%) rotate(-135deg);
border-color: orange;
}

&.cp{
& >.station > span {
transition: color .3s;
&:hover {
cursor: pointer;
color: orange;
text-decoration: underline;
}
}

&.online {
background-color: rgba(26,181,93,.14);
}
&.offline {
background-color: #fae3e6;
}
}

& >.stationsStatus {
display: flex;
justify-content: flex-start;
align-items: center;
min-width: 150px;
max-width: 200px;
& > ul > li {
padding: 2px 0;
}
}

& >.location {
display: flex;
align-items: center;

& > span:last-child {
transition: color .3s;
&:hover {
color: orange;
text-decoration: underline;
}
}
}

@media (max-width: 767px) {
padding: 8px 42px 8px 32px;
&:not(.cp) > span {
word-wrap: break-word;
&.stationsStatus {
min-width: 100px;
max-width: 100px;
}
}

&.header {
padding: 16px 8px 16px 32px;
}
&.cp{
flex-direction: column;
}
}

@media (max-width: 515px) {
padding: 8px 8px 8px 32px;
&.header {
padding: 16px 8px;
text-align: center;
}
&:not(.header) > span {
max-width: 33%;
}
& >.location > span:last-child {
width: calc(100% - 20px);
}
}
`;

export const ConnectorList = styled.ul`
min-height: 54px;
display: flex;
flex-direction: column;
justify-content: center;

& > li {
display: flex;
align-items: center;
}
`;

import {connect} from 'react-redux';
import { push } from 'react-router-redux'

import {fetchChargePointGroups, clearChargePointGroups} from '../../store/actions/chargePointGroupsActions';
import {postChargePointGroupAPI, updateChargePointGroupAPI} from '../../api/chargePointGroups';

import FormLocation from './FormLocation';

const mapStateToProps = (state) => ({
  isLoading: state.app.isLoading,
  lang: state.entities.languages,
  chargePointGroup: state.entities.chargePointGroup,
  markerPosition: state.entities.markerPosition,
});

const mapDispatchToProps = (dispatch) => {
  return {
    /*postChargePoint: (id, action) => {
      return dispatch(fetchChargePoint(id, action));
    },*/
    fetchLocationOnApi: (id) => {
      dispatch(fetchChargePointGroups(id))
    },
    createCPG: (data) => {
      postChargePointGroupAPI(data)
        .then(res => {
          dispatch(clearChargePointGroups());
          dispatch(push(`/`))
        })
    },
    updateCPG: (data, id) => {
      updateChargePointGroupAPI(data, id)
        .then(res => {
          dispatch(clearChargePointGroups());
          dispatch(push(`/`))
        })
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormLocation);

import TYPES from './actionTypes';

export const changeMarkerPosition = (data) => {
  return async (dispatch) => {
    dispatch({
      type: TYPES.CHANGE_MARKER_POSITION,
      payload: data
    });
  };
};

import React, {Component} from 'react';

import {
  SignInPage,
  BgContainer,
  SignInContainer,
  InputsWrapper,
  FailureMessage,
  SingInBtn,
  Footer
} from './style.js';

class SignIn extends Component {
  state = {
    login: '',
    password: '',
    isFailed: false
  };

  handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    this.setState({
      [name]: value
    });
  };

  handleFocus = (e) => {
    const name = e.target.name;

    this.setState({
      [`${name}Error`]: false
    }, () => {
      if (!this.state.loginError && !this.state.passwordError) {
        this.setState({
          isFailed: false
        });
      }
    });
  };

  handleKeyUp = (e) => {
    const ENTER_KEY_CODE = 13;

    if (e.keyCode === ENTER_KEY_CODE) {
      this.signIn();
    }
  };

  signIn = () => {
    const {
      signIn,
      getUsername
    } = this.props;

    const {
      login,
      password
    } = this.state;

    const credentials = {username: login, password};

    if (login.length > 0 && password.length > 0) {
      signIn(credentials)
        .then(() => {
          getUsername();
        })
        .catch((result) => {
          if (result) {
            this.setState({
              isFailed: true,
              loginError: true,
              passwordError: true
            });
          }
        });
    }
  };

  render() {
    const {
      login,
      password,
      isFailed,
      loginError,
      passwordError
    } = this.state;

    const isDisabled = login.length === 0 || password.length === 0;

    return (
      <SignInPage>
        <BgContainer/>
        <SignInContainer>

          <InputsWrapper>
            <input
              className={loginError ? 'error' : ''}
              autoFocus
              type="text"
              placeholder="Enter login..."
              name="login"
              value={login}
              onKeyUp={this.handleKeyUp}
              onFocus={this.handleFocus}
              onChange={this.handleChange}
            />
            <input
              className={passwordError ? 'error' : ''}
              type="password"
              placeholder="Enter password..."
              name="password"
              value={password}
              onKeyUp={this.handleKeyUp}
              onFocus={this.handleFocus}
              onChange={this.handleChange}
            />
            {
              isFailed &&
              <FailureMessage>
                Sign in failed! Please, check your login/password and
                try again.
              </FailureMessage>
            }
          </InputsWrapper>
          <div className="singIn-btn-wrapper">
            <SingInBtn
              className={isDisabled ? 'disabled' : ''}
              onClick={this.signIn}
            >
              Log In
            </SingInBtn>
          </div>

        </SignInContainer>
        <Footer>
          © 2019 <a href='https://www.quickpower.kiev.ua' target="_blank" rel="noopener noreferrer"> QP </a> All rights
          reserved
        </Footer>
      </SignInPage>
    );
  }
}

export default SignIn;

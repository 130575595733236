import React from 'react';

import {
  Wrapper
} from './style.js';

function Message(props) {
  const {local, text, active, onClose, borderRadius} = props;
    return (
      <Wrapper className={`${local ? 'local' : ''} ${active ? 'active' : ''}`}
               borderRadius={borderRadius}
               onClick={() => onClose()}>
        <button onClick={() => onClose()}>close</button>
        <span className="message">{text}</span>
      </Wrapper>
    );
}

export default Message;

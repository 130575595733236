import React from 'react';

import {
  Button,
} from './style.js'

const AddBtn = ({handleClick, title, del, cross, withOutBorder, color}) => {
  return (
      <Button onClick={handleClick}
              title={title}
              color={color || '#ffffff'}
              del={del}
              cross={cross}
              withOutBorder={withOutBorder}
      />
  )
};

export default AddBtn

import styled from 'styled-components'

export const Container = styled.div`
display: flex;
align-items: flex-start;
transition: all 0.3s ease-out, right 0.3s ease-out;

@media (max-width: 767px) {
flex-direction: column-reverse;
align-items: center;
font-size: 14px;

& > div {
width: 100%!important;

& > div:first-child {
height: 33vh!important;
}
}
}
`;

export const ImgContainer = styled.div`
width: 70%;
height: 100vh;
position: relative;
display: flex;
align-items: center;
justify-content: center;

&:before {
display: block;
position: absolute;
content: ' ';
width: 100%;
height: 100%;
background-image: url(${props => props.src});
background-position: center;
background-repeat: no-repeat;
background-size: cover;
filter: grayscale(.5) blur(10px);
}

`;

export const ImgBox = styled.img`
max-width: 80%;
height: auto;
max-height: 90%;
background-image: url(${props => props.src});
background-position: center;
background-repeat: no-repeat;
background-size: contain;
filter: grayscale(.5);
border-radius: 10px;
`;

import React from 'react';

const styles = {
  wrapper: {
    minWidth: '100%',
    minHeight: 'calc(100vh - 0px)',
    position: 'relative',
  },
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
  }
};

const NotFound = () => (
  <div style={styles.wrapper}>
    <div style={styles.container}>
      <h3>404 page not found</h3>
      <p>We are sorry but the page you are looking for does not exist.</p>
    </div>
  </div>
);


export default NotFound

import React from 'react';

import {
  InnerFrame,
  Button,
} from './style.js'

const ButtonRestart = ({text, ...props}) => {
  return (
  <InnerFrame>
    <Button
      {...props}
    >{text}</Button>
  </InnerFrame>
)
};

export default ButtonRestart

import React from 'react'
import {MainFielldBox, LabelForSelect, MainSelect, Err, MainInput, Border} from './style'

const Select = ({label, data, className, disabled, width, error, ...inputProps}) => (
  <MainFielldBox>
    <LabelForSelect>{label}</LabelForSelect>
    <Border
      className={`border ${className} ${inputProps.value ? 'active' : ''}`}
      width={width}
    >
      <MainSelect
        {...inputProps}
        disabled={disabled}
      >
        <option value="" disabled={true}/>
        {
          data.map((item, index) => (
            <option
              value={item.code || item.id}
              key={`${item.code || item.id}_${index}`}
              disabled={item.disabled}
            >
              {item.name || item.number || `${item.first_name} ${item.last_name}`}
            </option>
          ))}
      </MainSelect>
    </Border>
    {error && <Err>{error}</Err>}
  </MainFielldBox>
);

export default Select

export const SelectAutoComplete = ({className, id, label, data, withDetails, placeholder, readOnly, style, width, error, ...inputProps}) => (
  <MainFielldBox style={style}>
    {label ? <LabelForSelect>{label}</LabelForSelect> : null}
    <MainInput list={id} {...inputProps}
               className={`${className} ${inputProps.value ? 'active' : ''} ${readOnly ? 'disabled' : ''}`}
               autoComplete="off"
               placeholder={placeholder || ''}
               readOnly={readOnly}
               width={width || '230px'}
    />
    {
      withDetails
      ? <datalist
        id={id}
        className='details'>
        {
          data.map(item => (
            <option key={`${item.code || item.id}`}
                    value={item.name}
                    disabled={false}
            >
              {`${item.fullName}, level: ${item.level}${item.email ? `, email: ${item.email}` : ''}`}
            </option>
          ))
        }
      </datalist>
      : <datalist id={id}>
        {
          data.map(item => (
            <option key={`${item.code || item.id}`}
                    value={item.name}
                    disabled={false}
            />
          ))
        }
      </datalist>
    }
    {error && <Err>{error}</Err>}
  </MainFielldBox>
);

import {axiosInstance} from './axiosInstance';

export function chargePointGetAPI(id) {
  return (
    axiosInstance.get(`/api/v1/charge-points/${id}`)
  );
}

export function chargePointPostAPI(data) {
    return (
      axiosInstance.post(`/api/v1/charge-points/`, data)
    );
}

export function chargePointAddPhotoAPI(data, id) {
  const formData = new FormData();
  formData.append('photo', data[0]);
  formData.append('position', 0);
  return (
    axiosInstance.put(`/api/v1/charge-points/${id}`, formData, {headers: {'content-type': 'multipart/form-data'}})
  );
}

export function chargePointPatchAPI(data, id) {
  return (
    axiosInstance.patch(`/api/v1/charge-points/${id}`, data)
  );
}

export function chargePointRestartAPI(id, action) {
  return (
    axiosInstance.post(`/api/v1/charge-points/${id}/${action}`)
  );
}

/*global google*/
import React, {Component} from 'react'
import {compose, withProps, lifecycle} from 'recompose'
import {withScriptjs, withGoogleMap, GoogleMap, Marker} from 'react-google-maps'

const myMapStyles = [{stylers: [{'saturation': -100}, {'gamma': 0.8}, {'lightness': 4}, {'visibility': 'on'}]}];

const pin = require("./pin.png");

class MapNew extends Component {

    render() {
        const PolylineComponent = compose(
            withProps({
                googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyDfhfU1A3Qr6RsHX9hJFVVMMewvQsIHh4Q&v=3.exp&libraries=geometry,drawing,places",
                loadingElement: <div style={{height: `100vh`, background: '#E2E2E2'}}/>,
                containerElement: <div style={{width: `70%`, background: '#E2E2E2'}}/>,
                mapElement: <div style={{height: `100vh`, width: `100%`}}/>,

            }),
            lifecycle({
                componentDidMount() {
                    const refs = {};

                    this.setState({
                        position: null,
                        onMarkerMounted: ref => {
                            refs.marker = ref;
                        },

                        onPositionChanged: (handleChangePosition) => {
                            const position = refs.marker.getPosition();
                            const newPosition = {
                              lat: +position.toString().slice(1,position.toString().length-1).split(',')[0],
                              lng: +position.toString().slice(1,position.toString().length-1).split(',')[1],
                            };
                            handleChangePosition(newPosition);
                        }
                    })
                },
            }),
            withScriptjs,
            withGoogleMap,
        )((props) =>
            <GoogleMap
                defaultCenter={{
                  lat: this.props.defaultPosition.lat || 50.45,
                  lng: this.props.defaultPosition.lng || 30.523718,
                }}
                defaultZoom={15}
                defaultOptions={{
                    styles: myMapStyles,
                    zoomControl: true,
                    mapTypeControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    rotateControl: false,
                    fullscreenControl: true
                }}
            >
                <Marker
                    position={{
                        lat: this.props.defaultPosition.lat || 50.45,
                        lng: this.props.defaultPosition.lng || 30.523718,
                    }}
                    icon={{
                        url: pin,
                        anchor: new google.maps.Point(30, 60)
                    }}
                    draggable={true}
                    ref={props.onMarkerMounted}
                    onPositionChanged={()=>props.onPositionChanged(this.props.handleChangePosition)}
                />
            </GoogleMap>
        );

        return (
            <PolylineComponent/>
        )
    }
}

export default MapNew

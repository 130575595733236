import TYPES from '../actions/actionTypes';
import initialState from '../initialState';

export default function authReducer (state = initialState, action) {
	const {
		type,
		payload
	} = action;

	switch (type) {
		case TYPES.LOG_IN_SUCCESS:
			return {
				...state,
				isLoggedIn: !!window.localStorage.getItem('JWT_TOKEN')
			};
		case TYPES.LOG_OUT: {
			return {
				...state,
				isLoggedIn: !!window.localStorage.getItem('JWT_TOKEN')
			};
		}
		case TYPES.FETCH_USERNAME_SUCCESS:
			return {
				...state,
				username: `${payload.first_name} ${payload.last_name}`,
                role: payload.role,
			};
		default:
			return state;
	}
}

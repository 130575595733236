import React, {Component} from 'react';
import MapNew from '../../components/MapNew'

import FormLocation from "../../components/FormLocation/FormLocationContainer";

import {
  Container,
} from './style.js'

class CreateNewLocation extends Component {
  componentDidMount() {
    this.props.getLanguages();
    if (this.props.match.params.id) {
      this.props.fetchLocationOnApi(this.props.match.params.id)
    }
  }

  componentWillUnmount() {
    this.props.clearCPG()
  }

  render() {
    const {isLoading, chargePointGroup, changeMarkerPosition, lang } = this.props;
    return (
      isLoading || lang.length === 0 ?
        null :
        <Container>
          <FormLocation/>
          <MapNew
            handleChangePosition={changeMarkerPosition}
            defaultPosition={{
            lat: chargePointGroup ? chargePointGroup.location_lat : null,
            lng: chargePointGroup ? chargePointGroup.location_lng : null,
          }}
          />
        </Container>
    )
  }
}
export default CreateNewLocation

import TYPES from './actionTypes';
import {chargePointGetAPI, chargePointRestartAPI, chargePointPostAPI, chargePointAddPhotoAPI, chargePointPatchAPI} from '../../api/chargePoint';

export const fetchChargePoint = (id, action) => {
    return async (dispatch) => {
      dispatch({type: TYPES.FETCH_CHARGE_POINT_ACTION_PENDING});
      return chargePointRestartAPI(id, action)
        .then((result) => {
          dispatch({
            type: TYPES.FETCH_CHARGE_POINT_ACTION_SUCCESS,
            payload: result.data.status
          });
        }).catch((error) => {
          dispatch({
            type: TYPES.FETCH_CHARGE_POINT_ACTION_FAILURE,
            payload: error.response.data.status
          });
          if (error.response) {
            throw error.response.data;
          }
        });
    };
};

export const fetchGetChargePoint = (id) => {
    return async (dispatch) => {
      dispatch({type: TYPES.FETCH_CHARGE_POINT_PENDING});
      return chargePointGetAPI(id)
        .then((result) => {
          dispatch({
            type: TYPES.FETCH_CHARGE_POINT_SUCCESS,
            payload: result.data
          });
        }).catch((error) => {
          dispatch({type: TYPES.FETCH_CHARGE_POINT_FAILURE});
          if (error.response) {
            throw error.response.data;
          }
        })
    }
};

export const fetchCreateChargePoint = ({photos, ...data}) => {
  return async (dispatch) => {
    dispatch({type: TYPES.FETCH_CHARGE_POINT_ACTION_PENDING});
    return chargePointPostAPI(data)
      .then(result => {
        return chargePointAddPhotoAPI(photos, result.data['charge-point'])
          .then(result => {
            dispatch({
              type: TYPES.FETCH_CHARGE_POINT_ACTION_SUCCESS,
              /*payload: result.data.status*/
              payload: 'Station saved )'
            });
            }
          ).catch(error => {
            dispatch({
              type: TYPES.FETCH_CHARGE_POINT_ACTION_FAILURE,
              /*payload: error.response.data.status*/
              payload: 'error! Photo not saved ('
            });
            if (error.response) {
              throw error.response.data;
            }
          })
      }).catch(error => {
        dispatch({
          type: TYPES.FETCH_CHARGE_POINT_ACTION_FAILURE,
          /*payload: error.response.data.status*/
          payload: 'error! Station not saved ( Try again'
        });
        if (error.response) {
          throw error.response.data;
        }
      })
  }
};

export const fetchUpdateChargePoint = ({photos, ...data}, cp_id) => {
  return async (dispatch) => {
    dispatch({type: TYPES.FETCH_CHARGE_POINT_ACTION_PENDING});

    if(photos){
      if(Object.values(data).length >= 1){
        chargePointAddPhotoAPI(photos, cp_id)
          .then(() => chargePointPatchAPI(data, cp_id))
          .then(() => {
            dispatch({
              type: TYPES.FETCH_CHARGE_POINT_ACTION_SUCCESS,
              /*payload: result.data.status*/
              payload: 'Station info updated )'
            });
          }).catch(error => {
          dispatch({
            type: TYPES.FETCH_CHARGE_POINT_ACTION_FAILURE,
            /*payload: error.response.data.status*/
            payload: 'error! Station info not updated ('
          });
          if (error.response) {
            throw error.response.data;
          }
        })
      } else {
        return chargePointAddPhotoAPI(photos, cp_id)
          .then(result => {
              dispatch({
                type: TYPES.FETCH_CHARGE_POINT_ACTION_SUCCESS,
                /*payload: result.data.status*/
                payload: 'Photo updated )'
              });
            }
          ).catch(error => {
            dispatch({
              type: TYPES.FETCH_CHARGE_POINT_ACTION_FAILURE,
              /*payload: error.response.data.status*/
              payload: 'error! Photo not updated ('
            });
            if (error.response) {
              throw error.response.data;
            }
          })
      }
    } else if (data){
      return chargePointPatchAPI(data, cp_id)
        .then(result => {
            dispatch({
              type: TYPES.FETCH_CHARGE_POINT_ACTION_SUCCESS,
              /*payload: result.data.status*/
              payload: 'Station info updated )'
            });
          }
        ).catch(error => {
          dispatch({
            type: TYPES.FETCH_CHARGE_POINT_ACTION_FAILURE,
            /*payload: error.response.data.status*/
            payload: 'error! Station info not updated ('
          });
          if (error.response) {
            throw error.response.data;
          }
        })
    }
  }
};



export const clearChargePoint = () => {
  return async (dispatch) => {
    dispatch({type: TYPES.CLEAR_CHARGE_POINT});
  };
};

export const clearChargePointMessage = () => {
  return async (dispatch) => {
    dispatch({type: TYPES.CLEAR_CHARGE_POINT_ACTION});
  };
};

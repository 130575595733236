import axios from 'axios';
import store from '../store/';
import TYPES from '../store/actions/actionTypes';
import { push } from 'react-router-redux';


export const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_URL && process.env.REACT_APP_URL
});

axiosInstance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

export function unSetJWTTokenToHeader() {
    axiosInstance.defaults.headers.common['Authorization'] = '';
}

axiosInstance.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error.response.status === 401 || error.response.status === 403) {
        window.localStorage.removeItem('JWT_TOKEN');
        if (store.getState().auth.isLoggedIn) {
		    store.dispatch(push('/signIn'));
            store.dispatch({type: TYPES.LOG_OUT});
            unSetJWTTokenToHeader();
        }
    }
    console.log(error.response, '>>>>> in axios instance');
    return Promise.reject(error);
});

export function setJWTTokenToHeader(JWT_TOKEN=window.localStorage.getItem('JWT_TOKEN')) {
    if (JWT_TOKEN) {
        axiosInstance.defaults.headers.common['Authorization'] = `JWT ${JWT_TOKEN}`;
    }
}

setJWTTokenToHeader();

export default {
  LOG_IN_PENDING: 'LOG_IN_PENDING',
  LOG_IN_SUCCESS: 'LOG_IN_SUCCESS',
  LOG_IN_FAILURE: 'LOG_IN_FAILURE',

  LOG_OUT: 'LOG_OUT',

  CHANGE_MARKER_POSITION: 'CHANGE_MARKER_POSITION',

  FETCH_LANGUAGES_PENDING: 'FETCH_LANGUAGES_PENDING',
  FETCH_LANGUAGES_SUCCESS: 'FETCH_LANGUAGES_SUCCESS',
  FETCH_LANGUAGES_FAILURE: 'FETCH_LANGUAGES_FAILURE',

  FETCH_USERNAME_PENDING: 'FETCH_USERNAME_PENDING',
  FETCH_USERNAME_SUCCESS: 'FETCH_USERNAME_SUCCESS',
  FETCH_USERNAME_FAILURE: 'FETCH_USERNAME_FAILURE',

  CLEAR_CHARGE_POINT_GROUPS: 'CLEAR_CHARGE_POINT_GROUPS',

  FETCH_CHARGE_POINT_GROUP_PENDING: 'FETCH_CHARGE_POINT_GROUP_PENDING',
  FETCH_CHARGE_POINT_GROUP_SUCCESS: 'FETCH_CHARGE_POINT_GROUP_SUCCESS',
  FETCH_CHARGE_POINT_GROUP_FAILURE: 'FETCH_CHARGE_POINT_GROUP_FAILURE',

  CLEAR_CHARGE_POINT_GROUP: 'CLEAR_CHARGE_POINT_GROUP',

  FETCH_CHARGE_POINT_GROUPS_PENDING: 'FETCH_CHARGE_POINT_GROUPS_PENDING',
  FETCH_CHARGE_POINT_GROUPS_SUCCESS: 'FETCH_CHARGE_POINT_GROUPS_SUCCESS',
  FETCH_CHARGE_POINT_GROUPS_FAILURE: 'FETCH_CHARGE_POINT_GROUPS_FAILURE',

  CLEAR_CHARGE_POINT_ACTION: 'CLEAR_CHARGE_POINT_ACTION',

  FETCH_CHARGE_POINT_ACTION_PENDING: 'FETCH_CHARGE_POINT_ACTION_PENDING',
  FETCH_CHARGE_POINT_ACTION_SUCCESS: 'FETCH_CHARGE_POINT_ACTION_SUCCESS',
  FETCH_CHARGE_POINT_ACTION_FAILURE: 'FETCH_CHARGE_POINT_ACTION_FAILURE',

  CLEAR_CHARGE_POINT: 'CLEAR_CHARGE_POINT',

  FETCH_CHARGE_POINT_PENDING: 'FETCH_CHARGE_POINT_PENDING',
  FETCH_CHARGE_POINT_SUCCESS: 'FETCH_CHARGE_POINT_SUCCESS',
  FETCH_CHARGE_POINT_FAILURE: 'FETCH_CHARGE_POINT_FAILURE',

};

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App/AppContainer';

import { Provider } from 'react-redux';
import {Route} from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import store, { history } from './store/';

ReactDOM.render(
<Provider store={store}>
    <ConnectedRouter history={history}>
      <Route path="/" component={App}/>
    </ConnectedRouter>
    </Provider>,
document.getElementById('root')
);

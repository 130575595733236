import styled from 'styled-components'

const bg = require('../../assets/icons/bg_logo@2x.png');

export const SignInPage = styled.div`
display: flex;
justify-content: flex-end;
align-items: start;
width: 100%;
min-height: 100vh;
height: 100vh;
background-color: #F6F6F6;

@media all and (max-width: 768px) {
flex-direction: column;
justify-content: space-between;
}
`;

export const BgContainer = styled.div`
width: 60%;
height: calc(100vh - 60px);
align-self: flex-start;
background-image: url(${bg});
background-repeat: no-repeat;
background-size: contain;

@media all and (max-width: 768px) {
width: 100%;
height: 65%;
}

@media all and (max-width: 425px) {
height: 58%;
}
`;

export const SignInContainer = styled.section`
width: 40%;
height: calc(100% - 60px);
display: flex;
flex-direction: column;
justify-content: center;

& > .singIn-btn-wrapper {
max-width: 360px;
text-align: left;
}

@media all and (max-width: 768px) {
width: 100%;
height: 35%;
align-items: center;
> .singIn-btn-wrapper {
max-width: 80%;
width: 80%;
}
}

@media all and (max-width: 425px) {
height: 42%;
> .singIn-btn-wrapper {
text-align: center;
}
}
`;

export const InputsWrapper = styled.div`
max-width: 360px;
display: flex;
flex-direction: column;

font-size: 14px;
letter-spacing: 0.2px;

& > label {
margin-bottom: 7px;
font-weight: normal;
}

& > input {
height: 30px;
width: 100%;
font-size: 14px;

margin-bottom: 15px;
padding: 7px;

border: none;
border-bottom: 1px solid #A0A0A0;

outline: none;

background-color: inherit;

&:last-of-type {
margin-bottom: 0;
}

&.error {
border-color: #d0021b;
}
}

@media all and (max-width: 768px) {
max-width: 80%;
width: 80%;
> input {
margin-bottom: 35px;
}
}
`;

export const FailureMessage = styled.div`
font-size: 12px;
letter-spacing: 0.1px;
color: #d0021b;
`;

export const SingInBtn = styled.button`
line-height: 1;
font-size: 16px;
letter-spacing: 0.2px;
margin-top: 45px;
padding: 17px 85px;
background: #FF3911;
text-transform: uppercase;
text-align: center;
color: #ffffff;
border: none;
border-radius: 25px;
outline: none;
cursor: pointer;

&:hover {
background: #eb2511;
}

&.disabled {
background-color: rgba(155, 155, 155, 0.42);
cursor: default;

&:hover {
background-color: rgba(155, 155, 155, 0.42);
cursor: default!important;
}
}

@media all and (max-width: 768px) {
margin-top: 40px;
}
`;

export const Footer = styled.footer`
display: flex;
align-items: center;
justify-content: flex-start;
padding-bottom: 40px;

position: fixed;
bottom: 0;

width: 40%;

font-size: 14px;
letter-spacing: 0.2px;

color: #232323;

& > a {
margin: 0 5px;
color: #232323;
}

@media all and (max-width: 768px) {
position: inherit;
width: 80%;
margin: 0 auto;
padding-bottom: 30px;
}

@media all and (max-width: 425px) {
padding-bottom: 10px;
font-size: 12px;
justify-content: center;
}
`;

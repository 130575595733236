import React from 'react';

import {
  ButtonImg
} from './style.js';

const picture = require('../../../icons/picture.svg');

function Message(props) {
  return (
    <ButtonImg
      {...props}
    >
      <img src={picture} alt="show station"/>
    </ButtonImg>
  );
}

export default Message;

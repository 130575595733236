import styled from 'styled-components'

export const Button = styled.button`
z-index: 2;
color: #ffffff;
font-size: 13px;
border: none;
outline: none;
border-radius: 50%;
width: 50px;
height: 50px;
cursor: pointer;
background-image: 
-webkit-radial-gradient(50% 0%, 8% 50%, rgba(255,255,255,0.2) 0%, rgba(255,255,255, 0) 100%),
-webkit-radial-gradient(50% 100%, 12% 50%, rgba(255,255,255,0.2) 0%, rgba(255,255,255, 0) 100%),
-webkit-radial-gradient(0 50%, 50% 7%, rgba(255,255,255,0.3) 0%, rgba(255,255,255, 0) 100%),
-webkit-radial-gradient(100% 50%, 50% 5%, rgba(255,255,255,0.3) 0%, rgba(255,255,255, 0) 100%),

-webkit-repeating-radial-gradient(50% 50%, 100% 100%, rgba(44,0,0,0) 0%, rgba(44,0,0,0) 3%, rgba(44,0,0,0) 3.5%),
-webkit-repeating-radial-gradient(50% 50%, 100% 100%, rgba(255,30,30,0) 0%, rgba(255,30,30,0) 6%, rgba(255,30,30,0.1) 7.5%),
-webkit-repeating-radial-gradient(50% 50%, 100% 100%, rgba(210,25,25,0) 0%, rgba(210,25,25,0) 1.2%, rgba(210,25,25,0.2) 2.2%),
-webkit-radial-gradient(50% 50%, 200% 50%, rgb(130,10,10) 5%, rgb(120,10,10) 30%, rgb(170,17,17) 100%);
box-shadow: inset 0 0.25em 0.3em rgb(153,76,76);

&:active {
width: 50px;
height: 50px;
filter: brightness(97%);
transform: rotate(0.5deg);
box-shadow: inset rgba(61,0,0,0.7) 0 2px 2px 2px,
inset rgba(61,0,0,0.15) 0 2px 2px 5px;
}
`;

export const InnerFrame = styled.div`
position: relative;
margin: 0 auto;
z-index: 1;
font-size: 10px;
width: 50px;
height: 50px;
border-radius: 50%;
background-color: #000000;
box-shadow: inset 0 0.25rem 0.15rem rgba(199,199,199, 0.2);
display: flex;
align-items: center;
justify-content: center;

&:before {
font-size: 10px;
z-index: 1;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
display: block;
content: ' ';
width: 55px;
height: 55px;
border-radius: 50%;
background-color: rgb(27,27,27);
`;

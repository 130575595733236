import {connect} from 'react-redux';
import { push } from 'react-router-redux'

import {fetchCreateChargePoint, fetchUpdateChargePoint} from '../../store/actions/chargePointActions';
import {chargePointPostAPI} from '../../api/chargePoint';

import FormStation from './FormStation';

const mapStateToProps = (state) => ({
  isLoading: state.app.isLoading,
  chargePointGroups: state.entities.chargePointGroups,
  chargePoint: state.entities.chargePoint,
});

const mapDispatchToProps = (dispatch) => {
  return {
   /* fetchStationOnApi: (id) => {
      dispatch(fetchChargePoint(id))
    },*/
    createCP: (data) => {
      //chargePointPostAPI(data)
      dispatch(fetchCreateChargePoint(data))
    },
    updateCP: (data, id) => {
      dispatch(fetchUpdateChargePoint(data, id))
    },
    redirectSuccess: () => {
      dispatch(push(`/`))
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormStation);

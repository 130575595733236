export const validate = (values) => {
  let thereIsError = false;
  const err = {
    name: false,
    location: false,
    url: false,
    photos: false,
    charge_boxes: {}
  };

  for (const cb of Object.values(values.error.charge_boxes)) {
    err.charge_boxes[cb.uuid] = {
      ...cb, connectors: Object.values(cb.connectors).reduce((acc, conn) => {
        acc[conn.uuid] = {...conn};
        return acc
      }, {})
    }
  }

  if (!values.name) {
    err.name = 'required';
    thereIsError = true
  }
  if (!values.location) {
    err.location = 'required';
    thereIsError = true
  }
  if (!values.url) {
    err.url = 'required';
    thereIsError = true
  }
  if (!values.photos[0]) {
    err.photos = 'required';
    thereIsError = true
  }

  for (let cb of Object.values(values.charge_boxes)) {
    Object.entries(cb).forEach(([key, value]) => {
      if(key === 'id' || key === 'side' || key === 'current' || key === 'power' || key === 'status'){
        if (!value) {
          err.charge_boxes[cb.uuid][key] = 'required';
          thereIsError = true
        }
      } else if (key === 'connectors') {
        for (let conn of Object.values(value)) {
          Object.entries(conn).forEach(([key, value]) => {
            if (!value) {
              err.charge_boxes[cb.uuid]['connectors'][conn.uuid][key] = 'required';
              if(!thereIsError){
                thereIsError = true
              }
            }
          })
        }
      }
    })
  }

  if (thereIsError) {
    return err
  } else {
    return false
  }
};

import {connect} from 'react-redux';
import { push } from 'react-router-redux'

import {fetchChargePointGroups} from '../../store/actions/chargePointGroupsActions';
import {fetchChargePoint} from '../../store/actions/chargePointActions';

import Locations from './Locations';

const mapStateToProps = (state) => ({
  isLoading: state.app.isLoading,
  isWaitingResponse: state.entities.chargePoint.isWaitingResponse,
  responseStatus: state.entities.chargePoint.messageResponse,
  chargePointGroups: state.entities.chargePointGroups,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getChargePointGroups: () => {
      return dispatch(fetchChargePointGroups());
    },
    postChargePoint: (id, action) => {
      return dispatch(fetchChargePoint(id, action));
    },
    createNew: (item) => {
      dispatch(push(`/createNew${item}`))
    },
    goTo: (page, id) => {
      dispatch(push(`/${page}/${id}`))
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Locations);

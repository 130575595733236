import React, {Component, Fragment} from 'react';

import Websocket from 'react-websocket';

import ButtonRestart from '../../components/Buttons/RebootBtn/Button'
import AddBtn from '../../components/Buttons/AddBtn/Button'
import ButtonImg from '../../components/Buttons/ImgBtn/Button'
import Loader from '../../components/Loader/Loader'
import Message from '../../components/Message/Message'
import ImageModal from '../../components/ImageModal/ImageModal'
import StatusIndicator from '../../components/StatusIndicator/StatusIndicator'

import {
  Container,
  StationsList,
  ChargePointList,
  ConnectorList,
  ListItem,
} from './style.js'

class Locations extends Component {
  state = {
    photoUrl: {},
    activeLocation: [],
    activeStationId: null,
    locationUpdate: {},
    pointUpdate: {},
    boxUpdate: {},
  };

  componentDidMount() {
    this.props.getChargePointGroups();
    this.getStatus(this.props.chargePointGroups)
  }

  componentDidUpdate(prevProps) {
    const {
      chargePointGroups
    } = this.props;

    if (prevProps.chargePointGroups.length !== chargePointGroups.length) {
      this.getStatus(chargePointGroups)
    }
  }

  setPhotoUrl = info => {
    this.setState({
      photoUrl: {...info}
    })
  };

  setActiveLocation = id => {
    const {activeLocation} = this.state;
    if (activeLocation.some(locationId => locationId === id)) {
      const newActiveLocation = [...activeLocation];
      this.setState({
        activeLocation: newActiveLocation.filter(locationId => locationId !== id)
      })
    } else {
      this.setState(prevState => ({
        activeLocation: [...prevState.activeLocation, id]
      }))
    }
  };

  handleData = (data) => {
    const key = {
      'charge-location-update': 'locationUpdate',
      'charge-point-update': 'pointUpdate',
      'charge-box-update': 'boxUpdate',
    };

    let result = JSON.parse(data);

    let newInfo = {
      [result.data.id]: {
        online: result.data.online,
        status: result.data.status,
        status_details: result.data.status_details,
      }
    };

    this.setState(prevState => ({
      [key[result.event]]: {
        ...prevState[key[result.event]], ...newInfo
      }
    }));
  };

  handleClick = (cp, action) => {
    const isConf = window.confirm(`Перезагрузить станцию ${cp.name}?`);
    if (isConf) {
      let isCharging = cp.charge_boxes.some(cb => cb.stage === 'occupied' || cb.stage === 'reserved');
      if (isCharging) {
        let randomCode = Math.floor(Math.random() * Math.floor(9)) + 1;
        const checkCode = window.prompt(`Введите ${randomCode}`, '');
        if (randomCode === +checkCode) {
          this.setState({
            activeStationId: cp.id,
          });
          this.props.postChargePoint(cp.id, action)
        } else {
          alert('Вы ввели неправильное число :( ')
        }
      } else {
        this.setState({
          activeStationId: cp.id,
        });
        this.props.postChargePoint(cp.id, action)
      }
    }
  };

  getStatus = (data) => {
    const locationUpdate = {};
    const pointUpdate = {};
    const boxUpdate = {};
    for (let location of data) {
      locationUpdate[location.id] = {
        online: location.online,
        status: location.status,
        status_details: '',
      };

      if (location.charge_points[0]) {
        for (let cp of location.charge_points) {
          pointUpdate[cp.id] = {
            online: cp.online,
            status: cp.status,
            status_details: '',
          };

          if (cp.charge_boxes[0]) {
            for (let connector of cp.charge_boxes) {
              boxUpdate[connector.id] = {
                online: connector.online,
                status: connector.stage,
                status_details: connector.stage_details,
              }
            }
          }

        }
      }
    }
    this.setState({
      locationUpdate: {...locationUpdate},
      pointUpdate: {...pointUpdate},
      boxUpdate: {...boxUpdate},
    });
  };

  renderStationsStatus = (location) => {
    const {pointUpdate, boxUpdate} = this.state;

    return <ul>
      {
        location.charge_points.map(cp => (
          <li key={cp.id} style={{display: 'flex', alignItems: 'center'}}>
            <StatusIndicator
              key={cp.id}
              className={pointUpdate[cp.id].online ? 'online' : 'offline'}
              title={`${cp.name} - ${pointUpdate[cp.id].online ? 'online' : 'offline'}`}
            />
            <span style={{marginRight: 5}}>|</span>
            {
              cp.charge_boxes.map(cb => {
                const {id, conn_types, conn_power, conn_current} = cb;
                  if (pointUpdate[cp.id].online) {
                    return <StatusIndicator
                      key={id}
                      className={boxUpdate[id].status}
                      title={`${conn_types} / ${conn_power}kW / ${conn_current} - ${boxUpdate[id].status_details || boxUpdate[id].status}`}
                    />
                  } else {
                    return <StatusIndicator
                      key={id}
                      className={'offline'}
                      title={`${conn_types} / ${conn_power}kW / ${conn_current} - unavailable`}
                    />
                  }
                }
              )
            }
          </li>
        ))
      }
    </ul>
  };

  handleCreateNew = (name) => {
    this.props.createNew(name)
  };

  handleCloseMessage = () => {
    this.setState({
      activeStationId: ''
    })
  };

  render() {
    const {photoUrl, activeLocation, locationUpdate, pointUpdate, boxUpdate, activeStationId} = this.state;
    const {isLoading, chargePointGroups, isWaitingResponse, responseStatus, goTo} = this.props;

    return (
      <>
        <Websocket
          //url={`ws://${window.location.host}/api/v1/ws?${window.localStorage.getItem('JWT_TOKEN')}`}
          url={`wss://admin.qp.kiev.ua/api/v1/ws?${window.localStorage.getItem('JWT_TOKEN')}`}
          onMessage={this.handleData}
          debug={true}
        />

        {
          isLoading ? null : <Container>
        {
          Object.keys(locationUpdate).length > 0 && <StationsList>
            <ListItem className='header'>
              <span>location</span>
              <span>address</span>
              <span className='stationsStatus'>stations status</span>
              <span className='Btn'>
                <AddBtn handleClick={() => this.handleCreateNew('Location')}
                        title='add location'
                />
              </span>
            </ListItem>
            {
              chargePointGroups.map(location => (
                <Fragment key={location.id}>
                  <ListItem key={location.id}
                            onClick={() => this.setActiveLocation(location.id)}
                            className={`${location.charge_points[0] ? 'withCP' : ''}${activeLocation.some(locationId => locationId === location.id) ? ' active' : ''}`}
                  >
                    <span className='location'
                    >
                        <StatusIndicator
                          className={locationUpdate[location.id].online ? 'online' : 'offline'}
                          title={locationUpdate[location.id].online ? 'online' : 'offline'}
                        />
                      <span onClick={() => goTo('location', location.id)}>
                      {location.name}
                      </span>
                      </span>
                    <span>{location.address}</span>
                    <span className='stationsStatus'>{this.renderStationsStatus(location)}</span>

                  </ListItem>
                  {
                    location.charge_points[0] &&
                    <ChargePointList
                      className={activeLocation.some(locationId => locationId === location.id) ? 'active' : ''}>

                      <ListItem className='header'>
                        <span>station</span>
                        <span>model</span>
                        <span>firmware</span>
                        <span>status</span>
                        <span>connectors status</span>
                        <span className='center'>img</span>
                        <span className='center'>reboot</span>
                        <span className='center'>reset</span>
                        <span className='Btn'>
                          <AddBtn handleClick={() => this.handleCreateNew('Station')}
                                  title='add station'
                          />
                        </span>
                      </ListItem>

                      {
                        location.charge_points.map(cp => (

                          <ListItem key={cp.id}
                                    className={`cp ${pointUpdate[cp.id].online ? 'online' : 'offline'}`}
                          >
                            <span className='station'>
                              <span onClick={() => goTo('station', cp.id)}>
                                {cp.name || '...'}
                                </span>
                              </span>
                            <span>{cp.model || '...'}</span>
                            <span>{cp.firmware || '...'}</span>
                            <span>{pointUpdate[cp.id].status || '...'}</span>
                            <span>
                            {
                              cp.charge_boxes[0] ?
                                <ConnectorList>
                                  {
                                    cp.charge_boxes.map(connector => (
                                      <li key={connector.id}
                                      >
                                        <StatusIndicator
                                          className={pointUpdate[cp.id].online ? boxUpdate[connector.id].status : 'unavailable'}
                                          title={pointUpdate[cp.id].online ? boxUpdate[connector.id].status_details || boxUpdate[connector.id].status : 'unavailable'}
                                        />

                                        {connector.conn_types}
                                      </li>))
                                  }
                                </ConnectorList> : '...'
                            }
                        </span>
                            <span className='center'>
                              <ButtonImg
                                disabled={!cp.photos[0]}
                                onClick={() => this.setPhotoUrl(cp)}/>
                            </span>

                            <span className='center'>
                               <ButtonRestart
                                 onClick={() => this.handleClick(cp, 'reboot')}
                                 text='reboot'/>
                            </span>

                            <span className='center'>
                               <ButtonRestart
                                 onClick={() => this.handleClick(cp, 'reset')}
                                 text='reset'/>
                            </span>

                            {isWaitingResponse && activeStationId === cp.id ? <Loader local/> : null}

                            {activeStationId === cp.id ?
                              <Message
                                local
                                active={!!responseStatus}
                                text={responseStatus}
                                onClose={this.handleCloseMessage}
                              /> : null}

                          </ListItem>
                        ))
                      }
                    </ChargePointList>
                  }
                </Fragment>

              ))}
          </StationsList>
        }
        {
          photoUrl && photoUrl.photos ?
            <ImageModal
              handleClose={this.setPhotoUrl}
              pointUpdate={pointUpdate}
              boxUpdate={boxUpdate}
              photoUrl={photoUrl}
            /> : null
        }
        </Container>
        }
      </>
    )
  }
}

export default Locations

import React, {Component} from 'react';

import FormStation from "../../components/FormStation/FormStationContainer";

import {
  Container,
  ImgContainer,
  ImgBox
} from './style.js'
import Loader from "../../components/Loader/Loader";
import Message from "../../components/Message/Message";

class CreateNewStation extends Component {
  constructor(props) {
    super(props);
    this.state = {
    photo: ''
    }
  }

  componentDidMount() {
    this.props.getLanguages();
    this.props.getChargePointGroups();
    if (this.props.match.params.id) {
      this.props.getStation(this.props.match.params.id)
    }
  }

  componentWillUnmount() {
    this.props.clearCP()
  }

  handleCloseMessage = () => {
    this.props.clearMessage()
  };

  handlePhoto = (src) => {
    this.setState({
      photo: src
    })
  };

  render() {
    const {isLoading, chargePointGroups, chargePoint} = this.props;
    const {photo} = this.state;
    return (
      isLoading ?
        null :
        <Container>
          {
            chargePoint.isWaitingResponse
              ? <Loader/>
              : null
          }
          {
            chargePoint.messageResponse
              ? <Message
                local
                active={!!chargePoint.messageResponse}
                text={chargePoint.messageResponse}
                onClose={this.handleCloseMessage}
                borderRadius='none'
              />
              : null
          }
          {chargePointGroups[0] && <FormStation handlePhoto={this.handlePhoto}/>}
          <ImgContainer src={photo}>
          <ImgBox src={photo}/>
          </ImgContainer>
        </Container>
    )
  }
}

export default CreateNewStation

import styled from 'styled-components'

import arrow from '../../icons/arrow_for_select.svg'

export const MainFielldBox = styled.div`
position: relative;
display: flex;
flex-direction: row-reverse;
align-items: center;
justify-content: flex-end;
margin-top: 10px;
`;

export const Border = styled.div`
background: url(${arrow});
background-repeat: no-repeat;
background-position-y: center;
background-position-x: calc(100% - 10px);
border-radius: 0px;
border: solid 1px #c8c8c8;
height: 30px;
width: 70%;
overflow: hidden;

&.active {
border: solid 1px #fac715;
}

&.invalid {
border: solid 2px #fb397e; 
}
`;

export const MainSelect = styled.select`
background-color: inherit;
font-size: 14px;
text-align: left;
color: #4a4a4a;
width: 131%;
height: 100%;
border: none;
line-height: 26px;
vertical-align: middle;
padding-left: 10px;

> option {
padding: 0 10px;
}
&:focus{
border: none;
outline: none;
}

`;
export const MainInput = styled.input`
background-color: inherit;
width: 70%;
min-width: ${props => props.width};
height: 30px;
font-size: 14px;
text-align: left;
color: #4a4a4a;

border-radius: 0;
border: solid 1px #CCCCCC;
line-height: 1;
vertical-align: middle;
padding: 0 10px;

&:focus {
outline-color: #fac715;
}

&.active {
border: solid 1px #fac715;
}
> option {
padding: 0 10px;
}
&.invalid {
border: solid 1px #fb397e;
}
&.disabled {
opacity: 1!important;
}
&.disabled:focus {
border: solid 1px #c8c8c8;
}
`;

export const LabelForSelect = styled.div`
min-width: 100px;
width: 30%;
font-size: 12px;
line-height: 1;
padding: 0 10px;
font-weight: 100;
color: #80807f;
`;

export const Err = styled.span`
font-size: 12px;
line-height: 1;
font-weight: 100;
color: #fb397e;
position: absolute;
bottom: -10px;
left: 0;
`;

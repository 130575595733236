import React, {Component} from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';

import {
  AppContainer,
  AppMain,
} from './style.js';

import Loader from '../components/Loader/Loader';
import SignIn from '../pages/SignIn/SignInContainer';
import NotFound from '../components/NotFound/NotFound';
import Locations from '../pages/Locations/LocationsContainer';
import CreateNewLocation from '../pages/CreateNewLocation/CreateNewLocationContainer';
import CreateNewStation from '../pages/CreateNewStation/CreateNewStationContainer';

class App extends Component {
  unAuthorizedRoutes = () => {
    return ([
      <Route key="/signIn" exact path="/signIn" component={SignIn}/>,
      <Redirect key='redirectSignIn' to="/signIn"/>
    ]);
  };

  authorizedRoutes = () => {
    return ([
      <Route key="/" exact path="/" component={Locations}/>,
      <Route key="/signIn" exact path="/signIn" render={() => <Redirect key='redirect/' to="/"/>}/>,
      <Route key="/createNewLocation" path="/createNewLocation" component={CreateNewLocation}/>,
      <Route key="/location" path="/location/:id" component={CreateNewLocation}/>,
      <Route key="/CreateNewStation" path="/CreateNewStation" component={CreateNewStation}/>,
      <Route key="/station" path="/station/:id" component={CreateNewStation}/>,
      <Route key="NotFound" component={NotFound}/>
    ]);
  };

  render() {
    const {
      isLoggedIn,
      isLoading,
      //pathname,
    } = this.props;

    return (
      <AppContainer>
        {
          isLoading ?
            <Loader/> : null
        }
        <AppMain>
            <Switch>
              {
                isLoggedIn
                  ? this.authorizedRoutes()
                  : this.unAuthorizedRoutes()
              }
            </Switch>
        </AppMain>
      </AppContainer>
    );
  }
}

export default App;


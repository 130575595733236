import React, {Component} from 'react';
import {isEqual} from 'lodash'
import AddBtn from '../../components/Buttons/AddBtn/Button'
import MainField from '../../components/MainField'
import {SelectAutoComplete} from '../../components/Select'
import BlockInteractiveHover from '../../components/BlockInteractiveHover/BlockInteractiveHover'
import {validate} from './validate'

import {
  Form,
  Title,
  TitleBlock,
  ChargeBoxBlock,
  ConnectorBlock,
  SubmitBtn
} from '../FormLocation/style.js'

const combo = require('../../icons/combo.png');
const chademo = require('../../icons/chademo.png');
const type_2 = require('../../icons/type-2.png');

const imageConnector = {
  'combo': combo,
  'chademo': chademo,
  'type-2': type_2,
};

const selectData = {
  'side': [{id: 'left', name: 'left'}, {id: 'right', name: 'right'}],
  'current': [{id: 'AC', name: 'AC'}, {id: 'DC', name: 'DC'}],
  'status': [
    {id: 'Available', name: 'Available'},
    {id: 'Occupied', name: 'Occupied'},
    {id: 'Reserved', name: 'Reserved'},
    {id: 'Unavailable', name: 'Unavailable'},
    {id: 'Faulted', name: 'Faulted'},
    ],
  'connector_type': [{id: 'type-2', name: 'type-2'}, {id: 'chademo', name: 'chademo'}, {id: 'combo', name: 'combo'}],
};

const uuidv1 = require('uuid/v1');

class FormStation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rendering: false,
      name: '',
      location: '',
      url: '',
      protocol_compression: false,
      reg_allowed: true,
      photos: [],
      charge_boxes: {
        1: {
          uuid: 1,
          id: 1,
          side: '',
          current: '',
          power: '',
          status: '',
          connectors: {
            1: {
              uuid: 1,
              mode: '',
              connector_type: '',
              label: '',
            }
          },
        }
      },
      error: {
        name: false,
        location: false,
        url: false,
        protocol_compression: false,
        photos: false,
        charge_boxes: {
          1: {
            uuid: 1,
            id: false,
            side: false,
            current: false,
            power: false,
            status: false,
            connectors: {
              1: {
                uuid: 1,
                mode: false,
                connector_type: false,
                label: false,
              }
            },
          }
        }
      }
    };

    this.fileInput = React.createRef();
  }

  getState = (chargePoint) => {
    const {chargePointGroups} = this.props;
    const newState = {
      error: {
        name: false,
        location: false,
        url: false,
        protocol_compression: false,
        photos: false,
        charge_boxes: {}
      }
    };

    if (chargePoint.uuid) {
      newState.name = chargePoint.name;
      newState.location = chargePointGroups.filter(cpg => cpg.id === chargePoint.group)[0].name;
      newState.url = chargePoint.url;
      newState.protocol_compression = chargePoint.protocol_compression ? true : false;
      newState.reg_allowed = chargePoint.reg_allowed;
      newState.photos = [...chargePoint.charge_point_photo.map(el => (
          {
            file: null,
            id: el.photo,
            src: el.photo
          }
        )
      )];
      newState.charge_boxes = {};

      for (let cb of chargePoint.charge_boxes) {
        newState.charge_boxes[cb.uuid] = {
          ...cb, connectors: cb.connectors.reduce((acc, conn) => {
            acc[conn.uuid] = {...conn};
            return acc
          }, {})
        };
        newState.error.charge_boxes[cb.uuid] = {
          uuid: cb.uuid,
          id: false,
          side: false,
          current: false,
          power: false,
          status: false,
          connectors: cb.connectors.reduce((acc, conn) => {
            acc[conn.uuid] = {uuid: conn.uuid, mode: false, connector_type: false, label: false,};
            return acc
          }, {})
        };
      }

      this.setState({
        rendering: true,
        ...newState,
      }, () => {
        this.props.handlePhoto(this.state.photos[0].src)
      });

    } else {
      this.setState({
        rendering: true,
      })
    }
  };

  componentDidMount() {
    const {chargePoint} = this.props;
    this.getState(chargePoint);
  }

  componentDidUpdate(prevProps) {
    const {chargePoint} = this.props;

    if (prevProps.chargePoint.uuid !== chargePoint.uuid) {
      this.getState(chargePoint)
    }
  }

  handleChange = (event, cb_id, conn_id) => {
    event.stopPropagation();
    const target = event.target;
    if (target.type === 'file') {
      if (target.files.length) {
        const arrFiles = Array.from(target.files);
        const files = [];

        for (let file of arrFiles) {
          const src = window.URL.createObjectURL(file);
          if (this.state.photos.some(photo => photo.id === file.name)) {

          } else {
            files.push({file, id: file.name, src})
          }
        }

        if (files.length) {
          this.setState(prevState => ({
            //photos: [...prevState.photos, ...files]
            photos: [...files]
          }), () => {
            this.props.handlePhoto(this.state.photos[0].src)
          });
        }
      }
    } else {
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const name = target.name;
      if (cb_id) {
        if (conn_id) {
          this.setState(prevState => ({
            charge_boxes: {
              ...prevState.charge_boxes,
              [cb_id]: {
                ...prevState.charge_boxes[cb_id],
                connectors: {
                  ...prevState.charge_boxes[cb_id].connectors,
                  [conn_id]: {
                    ...prevState.charge_boxes[cb_id].connectors[conn_id],
                    [name]: value
                  }
                }
              }
            }
          }));
        } else {
            this.setState(prevState => ({
              charge_boxes: {
                ...prevState.charge_boxes,
                [cb_id]: {
                  ...prevState.charge_boxes[cb_id],
                  [name]: (name === 'id' || name === 'power') ? +value : value
                }
              }
            }));
        }
      } else {
        this.setState({
          [name]: value
        });
      }
    }
  };

  handleDeleteError = (name, cb_id, conn_id) => {
    if (cb_id) {
      if (conn_id) {
        this.setState(prevState => ({
          error: {
            ...prevState.error,
            charge_boxes: {
              ...prevState.error.charge_boxes,
              [cb_id]: {
                ...prevState.error.charge_boxes[cb_id],
                'connectors': {
                  ...prevState.error.charge_boxes[cb_id]['connectors'],
                  [conn_id]: {
                    ...prevState.error.charge_boxes[cb_id]['connectors'][conn_id],
                    [name]: false
                  }
                }
              }
            }
          }
        }));
      } else {
        this.setState(prevState => ({
          error: {
            ...prevState.error,
            charge_boxes: {
              ...prevState.error.charge_boxes,
              [cb_id]: {
                ...prevState.error.charge_boxes[cb_id],
                [name]: false
              }
            }
          }
        }));
      }
    } else {
      this.setState(prevState => ({
        error: {
          ...prevState.error,
          [name]: false
        }
      }));
    }
  };

  isValidForm = () => {
    const err = validate(this.state);
    if (err) {
      this.setState({
        error: {...err}
      });
      return false
    } else {
      return true
    }
  };

  handlePatch = (event) => {
    const {name, location, url, protocol_compression, reg_allowed, photos, charge_boxes} = this.state;
    const {chargePointGroups, chargePoint} = this.props;
    event.stopPropagation();
    event.preventDefault();
    if (this.isValidForm()) {

      const initialValues = {
        name: chargePoint.name ? chargePoint.name : '',
        location: chargePoint.group ? chargePointGroups.filter(cpg => cpg.id === chargePoint.group)[0].name : '',
        url: chargePoint.url ? chargePoint.url : '',
        protocol_compression: chargePoint.protocol_compression ? true : false,
        reg_allowed: chargePoint.reg_allowed ? chargePoint.reg_allowed : false,
        photos: chargePoint.charge_point_photo ? [...chargePoint.charge_point_photo.map(el => ({
          file: null,
          id: el.photo,
          src: el.photo
        }))] : [],
        charge_boxes: chargePoint.charge_boxes ? (() => {
          const chargeBoxes = {};
          for (let cb of chargePoint.charge_boxes) {
            chargeBoxes[cb.uuid] = {
              ...cb, connectors: cb.connectors.reduce((acc, conn) => {
                acc[conn.uuid] = {...conn};
                return acc
              }, {})
            };
          }
          return chargeBoxes
        })() : {},
      };

      const updatedValues = {
        name,
        location,
        url,
        protocol_compression,
        reg_allowed,
        photos,
        charge_boxes
      };

      const formKey = Object.keys(initialValues);

      const fromChanges = formKey.reduce((acc, item) => {
        if (item === 'photos') {
          if (!isEqual(initialValues[item], updatedValues[item])) {
            acc[item] = updatedValues[item].map(photo => photo.file)
          }
        } else if (item === 'charge_boxes') {
          for (let cb_id of Object.keys(updatedValues[item])) {
            if (initialValues[item][cb_id]) {
              let arrKey = ['id', 'side', 'current', 'power', 'status', 'connectors'];
              for(let key of arrKey){
                if (key === 'connectors'){

                  for (let conn_id of Object.keys(updatedValues[item][cb_id][key])) {
                    if(initialValues[item][cb_id][key][conn_id]){
                      let arrKeyConn = ['mode', 'connector_type', 'label'];
                      for(let keyConn of arrKeyConn){
                        if (initialValues[item][cb_id][key][conn_id][keyConn] !== updatedValues[item][cb_id][key][conn_id][keyConn]) {
                          acc[item] ? acc[item] = {...acc[item]} : acc[item] = {};
                          acc[item][cb_id] ? acc[item][cb_id] = {...acc[item][cb_id]} : acc[item][cb_id] = {};
                          acc[item][cb_id][key] ? acc[item][cb_id][key] = {...acc[item][cb_id][key]} : acc[item][cb_id][key] = {};
                          acc[item][cb_id][key][conn_id] ? acc[item][cb_id][key][conn_id] = {...acc[item][cb_id][key][conn_id]} : acc[item][cb_id][key][conn_id] = {};
                          acc[item][cb_id][key][conn_id]['uuid'] = conn_id;
                          acc[item][cb_id][key][conn_id][keyConn] = updatedValues[item][cb_id][key][conn_id][keyConn]
                        }
                      }
                    } else {
                      acc[item] ? acc[item] = {...acc[item]} : acc[item] = {};
                      acc[item][cb_id] ? acc[item][cb_id] = {...acc[item][cb_id]} : acc[item][cb_id] = {};
                      acc[item][cb_id][key] ? acc[item][cb_id][key] = {...acc[item][cb_id][key]} : acc[item][cb_id][key] = {};
                      acc[item][cb_id][key][conn_id] = {...updatedValues[item][cb_id][key][conn_id]}
                    }
                  }

                } else {
                  if (initialValues[item][cb_id][key] !== updatedValues[item][cb_id][key]) {
                    acc[item] ? acc[item] = {...acc[item]}: acc[item] = {};
                    acc[item][cb_id] ? acc[item][cb_id] = {...acc[item][cb_id]} : acc[item][cb_id] = {};
                    acc[item][cb_id]['uuid'] = cb_id;
                    acc[item][cb_id][key] = updatedValues[item][cb_id][key]
                  }
                }
              }
            } else {
              acc[item] ? acc[item] = {...acc[item]}: acc[item] = {};
              acc[item][cb_id] = {...updatedValues[item][cb_id]}
            }
          }
        } else {
          if (initialValues[item] !== updatedValues[item]) {
            if (item === 'location') {
              acc.group = chargePointGroups.filter(cpg => cpg.name === updatedValues[item])[0].id
            } else if (item === 'protocol_compression'){
              acc[item] = updatedValues[item] ? 'gzip' : null
            } else {
              acc[item] = updatedValues[item]
            }
          }
        }
        return acc
      }, {});
      const newDataStation = fromChanges.charge_boxes ? {
        ...fromChanges,
        charge_boxes: [...Object.values(fromChanges.charge_boxes)
          .map(el => ( el.connectors ? {...el, connectors: [...Object.values(el.connectors)]} : {...el} ))] } : {...fromChanges};
      this.props.updateCP(newDataStation, this.props.chargePoint.uuid);
    }
  };

  sentDataToApi = (event) => {
    event.stopPropagation();
    event.preventDefault();
    if (this.isValidForm()) {
      const newDataStation = {
        name: this.state.name,
        group: this.props.chargePointGroups.filter(cpg => cpg.name === this.state.location)[0].id,
        url: this.state.url,
        protocol_compression: this.state.protocol_compression ? 'gzip' : null,
        reg_allowed: this.state.reg_allowed,
        photos: this.state.photos.map(photo => photo.file),
        charge_boxes: Object.values(this.state.charge_boxes).map(cb => {
          const {id, side, current, power, status, connectors, ...other} = cb;
          const connArr = Object.values(connectors).map(({uuid, ...other}) => ({...other}));
          return {id, side, current, power, status, connectors: connArr}
        })
      };
      this.props.createCP(newDataStation);
    }
  };

  getProgress = () => {
    const checkArr = [
      'name',
      'location',
      'url',
      /*'protocol_compression',*/
      'photos',
    ];

    let checkedItem = 0;

    for (let key of checkArr) {
      if (key === 'photos') {
        checkedItem += this.state[key][0] ? 1 : 0
      } else {
        checkedItem += this.state[key] !== '' ? 1 : 0
      }
    }
    /*return (checkedItem / checkArr.length) * 100;*/
    return 100;
  };

  triggerInput = (e) => {
    e.persist();
    this.handleDeleteError('photos');
    this.fileInput.current.click()
  };

  deleteImg = (event, id) => {
    event.stopPropagation();
    event.preventDefault();
    const photosCopy = [...this.state.photos];

    this.setState({
      photos: [...photosCopy.filter(photo => photo.id !== id)]
    }, () => {
      this.props.handlePhoto(this.state.photos[0] ? this.state.photos[0].src : '')
    })
  };

  addChargeBox = (e) => {
    e.preventDefault();
    let countChargeBox = Object.keys(this.state.charge_boxes).length + 1;
    const uuid = uuidv1();

    const newChargeBox = {
      [uuid]: {
        uuid: uuid,
        id: countChargeBox,
        side: '',
        current: '',
        power: '',
        status: '',
        connectors: {},
      }
    };

    const newChargeBoxError = {
      [uuid]: {
        uuid: uuid,
        id: false,
        side: false,
        current: false,
        power: false,
        status: false,
        connectors: {},
      }
    };

    this.setState(prevState => ({
      charge_boxes: {
        ...prevState.charge_boxes,
        ...newChargeBox
      },
      error: {
        ...prevState.error,
        charge_boxes: {
          ...prevState.error.charge_boxes,
          ...newChargeBoxError
        }
      }
    }))
  };

  delChargeBox = (e, id) => {
    e.preventDefault();
    const charge_boxesCopy = {...this.state.charge_boxes};
    delete charge_boxesCopy[id];
    this.setState({
      charge_boxes: {
        ...charge_boxesCopy
      }
    })
  };

  addConnector = (e, cb_id) => {
    e.preventDefault();
    const uuid = uuidv1();
    const newConnectors = {
      [uuid]: {
        uuid: uuid,
        mode: '',
        connector_type: '',
        label: '',
      }
    };

    const newConnectorsError = {
      [uuid]: {
        uuid: uuid,
        mode: false,
        connector_type: false,
        label: false,
      }
    };

    this.setState(prevState => ({
      charge_boxes: {
        ...prevState.charge_boxes,
        [cb_id]: {
          ...prevState.charge_boxes[cb_id],
          connectors: {
            ...prevState.charge_boxes[cb_id].connectors,
            ...newConnectors
          }
        }
      },
      error: {
        ...prevState.error,
        charge_boxes: {
          ...prevState.error.charge_boxes,
          [cb_id]: {
            ...prevState.error.charge_boxes[cb_id],
            connectors: {
              ...prevState.error.charge_boxes[cb_id].connectors,
              ...newConnectorsError
            }
          }
        }
      }
    }))
  };

  delConnector = (e, cb_id, conn_id) => {
    e.preventDefault();
    const charge_boxesCopy = {...this.state.charge_boxes};

    const connectorsCopy = {...this.state.charge_boxes[cb_id].connectors};

    delete connectorsCopy[conn_id];

    this.setState({
      charge_boxes: {
        ...charge_boxesCopy,
        [cb_id]: {
          ...charge_boxesCopy[cb_id],
          connectors: {
            ...connectorsCopy
          }
        }
      }
    })
  };

  render() {
    const {rendering, name, location, url, protocol_compression, reg_allowed, charge_boxes, photos, error} = this.state;
    const {isLoading, chargePointGroups, chargePoint} = this.props;

    const isEdit = !!chargePoint.uuid;

    const oldInfo = {
      name: chargePoint.name ? chargePoint.name : '',
      location: chargePoint.group ? chargePointGroups.filter(cpg => cpg.id === chargePoint.group)[0].name : '',
      url: chargePoint.url ? chargePoint.url : '',
      protocol_compression: chargePoint.protocol_compression ? true : false,
      reg_allowed: chargePoint.reg_allowed ? chargePoint.reg_allowed : false,
      photos: chargePoint.charge_point_photo ? [...chargePoint.charge_point_photo.map(el => ({
        file: null,
        id: el.photo,
        src: el.photo
      }))] : [],
      charge_boxes: chargePoint.charge_boxes ? (() => {
        const chargeBoxes = {};
        for (let cb of chargePoint.charge_boxes) {
          chargeBoxes[cb.uuid] = {
            ...cb, connectors: cb.connectors.reduce((acc, conn) => {
              acc[conn.uuid] = {...conn};
              return acc
            }, {})
          };
        }
        return chargeBoxes
      })() : {},
    };

    const newInfo = {
      name,
      location,
      url,
      protocol_compression,
      reg_allowed,
      photos,
      charge_boxes
    };

    let isChanged = !isEqual(oldInfo, newInfo);

    const chargeBoxes = Object.values(charge_boxes).map(cb => (
      <ChargeBoxBlock key={cb.uuid}>
        <span className='Btn'>
          <AddBtn handleClick={(e) => this.delChargeBox(e, cb.uuid)}
                  title='delete charge box'
                  cross={true}
                  withOutBorder={true}
                  color='#c8c8c8'
          />
        </span>
        <TitleBlock>
          <span>{`charge box ${cb.id}`}</span>
        </TitleBlock>
        {
          [
            {name: 'id', type: 'number', component: 'input'},
            {name: 'side', type: 'text', component: 'select'},
            {name: 'current', type: 'text', component: 'select'},
            {name: 'power', type: 'number', component: 'input'},
            {name: 'status', type: 'text', component: 'select'}
          ].map(el => {
            if (el.component === 'select') {
              return <SelectAutoComplete
                key={el.name}
                name={el.name}
                className={error.charge_boxes[cb.uuid][el.name] ? 'invalid' : ''}
                label={el.name}
                value={charge_boxes[cb.uuid][el.name]}
                data={selectData[el.name]}
                onChange={(e) => this.handleChange(e, cb.uuid)}
                id={el.name}
                error={error.charge_boxes[cb.uuid][el.name]}
                onFocus={() => this.handleDeleteError(el.name, cb.uuid)}
              />
            } else {
              return <MainField
                key={el.name}
                name={el.name}
                type={el.type}
                value={charge_boxes[cb.uuid][el.name]}
                onChange={(e) => this.handleChange(e, cb.uuid)}
                className={error.charge_boxes[cb.uuid][el.name] ? 'invalid' : ''}
                label={el.name}
                error={error.charge_boxes[cb.uuid][el.name]}
                onFocus={() => this.handleDeleteError(el.name, cb.uuid)}
              />
            }
          })
        }

        <TitleBlock>
          <span>connectors</span>
          <span className='Btn'>
          <AddBtn handleClick={(e) => this.addConnector(e, cb.uuid)}
                  title='add connector'
                  color='#c8c8c8'
          />
        </span>
        </TitleBlock>
        {
          Object.values(cb.connectors).map(conn => (
            <ConnectorBlock key={conn.uuid}>
              <BlockInteractiveHover id={conn.uuid}>
              {
                [
                  {name: 'mode', type: 'text', component: 'input'},
                  {name: 'connector_type', type: 'text', component: 'select'},
                  {name: 'label', type: 'text', component: 'input'},
                ].map(el => {
                    if (el.component === 'select') {
                      return <SelectAutoComplete
                        key={el.name}
                        name={el.name}
                        className={error.charge_boxes[cb.uuid]['connectors'][conn.uuid][el.name] ? 'invalid' : ''}
                        label={el.name === 'connector_type' ? 'type' : el.name}
                        value={charge_boxes[cb.uuid]['connectors'][conn.uuid][el.name]}
                        data={selectData[el.name]}
                        onChange={(e) => this.handleChange(e, cb.uuid, conn.uuid)}
                        id={el.name}
                        error={error.charge_boxes[cb.uuid]['connectors'][conn.uuid][el.name]}
                        onFocus={() => this.handleDeleteError(el.name, cb.uuid, conn.uuid)}
                      />

                    } else {
                      return <MainField
                        key={el.name}
                        name={el.name}
                        type={el.type}
                        value={charge_boxes[cb.uuid]['connectors'][conn.uuid][el.name]}
                        onChange={(e) => this.handleChange(e, cb.uuid, conn.uuid)}
                        className={error.charge_boxes[cb.uuid]['connectors'][conn.uuid][el.name] ? 'invalid' : ''}
                        label={el.name}
                        error={error.charge_boxes[cb.uuid]['connectors'][conn.uuid][el.name]}
                        onFocus={() => this.handleDeleteError(el.name, cb.uuid, conn.uuid)}
                      />
                    }
                  }
                )
              }
              <span className='Btn'>
                <AddBtn handleClick={(e) => this.delConnector(e, cb.uuid, conn.uuid)}
                        title='delete connector'
                        color='#c8c8c8'
                        cross={true}
                        withOutBorder={true}
                />
              </span>
              <img src={imageConnector[`${charge_boxes[cb.uuid]['connectors'][conn.uuid]['connector_type']}`]} alt=""/>
              </BlockInteractiveHover>
            </ConnectorBlock>
          ))
        }
      </ChargeBoxBlock>
    ));

    return (
      (!isLoading && rendering) &&
      <Form
        autocomplete="off"
        progress={this.getProgress()}
      >
        <Title>{isEdit ? `${chargePoint.name}` : 'New Station'}</Title>

        <MainField
          name="name"
          type="text"
          value={name}
          onChange={this.handleChange}
          className={error.name ? 'invalid' : ''}
          label="name"
          error={error.name}
          onFocus={() => this.handleDeleteError('name')}
        />

        <SelectAutoComplete
          name="location"
          className={error.location ? 'invalid' : ''}
          label="location"
          value={location}
          data={chargePointGroups.map(cpg => ({id: cpg.id, name: cpg.name}))}
          onChange={this.handleChange}
          id='location'
          error={error.location}
          onFocus={() => this.handleDeleteError('location')}
        />

        <MainField
          name="url"
          type="text"
          placeholder='http://00.000.000.0:00000'
          value={url}
          onChange={this.handleChange}
          className={error.url ? 'invalid' : ''}
          label="url"
          error={error.url}
          onFocus={() => this.handleDeleteError('url')}
        />

        <MainField
          name="protocol_compression"
          type="checkbox"
          id='gzip'
          checked={protocol_compression}
          onChange={this.handleChange}
          className={`radio ${error.protocol_compression ? 'invalid' : ''}`}
          label="gzip"
          error={error.protocol_compression}
          onFocus={() => this.handleDeleteError('protocol_compression')}
        />

        <MainField
          name="reg_allowed"
          type="checkbox"
          id='reg_allowed'
          checked={reg_allowed}
          onChange={this.handleChange}
          className={`radio ${error.reg_allowed ? 'invalid' : ''}`}
          label="reg_allowed"
          error={error.reg_allowed}
          onFocus={() => this.handleDeleteError('reg_allowed')}
        />

        <MainField
          name="photos"
          type="file"
          inputRef={this.fileInput}
          accept="image/*"
          //multiple
          triggerInput={this.triggerInput}
          onChange={this.handleChange}
          className={error.photos ? 'invalid' : ''}
          label="photo"
          error={error.photos}
          onFocus={() => this.handleDeleteError('photos')}
        >
          {
            photos.map(({file, src, id}) => (
              <div key={id}
                   onClick={(event) => this.deleteImg(event, id)}
              >
                <img src={src}
                     alt=""
                     title={id}
                />
              </div>

            ))
          }
        </MainField>

        {
          chargeBoxes
        }
        <TitleBlock>
          <span>add charge box</span>
          <span className='Btn'>
                <AddBtn handleClick={this.addChargeBox}
                        title='add charge box'
                        color='#c8c8c8'
                />
              </span>
        </TitleBlock>

        <div style={{marginTop: 50}}>
          <SubmitBtn
            className={isEdit ?
              (isChanged ? '' : 'disabled') :
              (this.getProgress() === 100 ? '' : 'disabled')
            }
            disabled={isEdit ?
              !isChanged :
              this.getProgress() !== 100
            }
            type="onSubmit"
            onClick={isEdit ? this.handlePatch : this.sentDataToApi}
          >
            {isEdit ? 'SAVE CHANGES' : 'SAVE'}
          </SubmitBtn>
        </div>
      </Form>
    )
  }
}

export default FormStation

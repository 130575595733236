import styled from 'styled-components'

export const Wrapper = styled.div`
display: flex;
position: relative;
top:0;
right: -100%;
width:100%;
height:100%;
justify-content: center;
align-items: center;
background: rgba(0,0,0,0.75);
z-index: 999;
transition: right 0.4s ease-in;

> button {
padding: 5px 10px;
color: #ffffff;
position: absolute;
top: 0;
left: 0;
cursor: pointer;
}

&.active {
right: 0;
}

> .message {
  width: 50%;
  text-align: center;
  color: #ffffff;
  }

&.local {
position: absolute;
width:100%!important;
border-radius: ${props => props.borderRadius ? props.borderRadius : '10px'};
}
`;

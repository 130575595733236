import styled from 'styled-components'

export const Container = styled.div`
display: flex;
align-items: flex-start;
transition: all 0.3s ease-out, right 0.3s ease-out;

@media (max-width: 767px) {
flex-direction: column-reverse;
align-items: center;
font-size: 14px;

& > div {
width: 100%!important;

& > div:first-child {
height: 33vh!important;
}
}
}
`;

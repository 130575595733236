import TYPES from '../actions/actionTypes';
import initialState from '../initialState';

export default function appReducer(state = initialState, action) {
  const {
    type
  } = action;

  switch (type) {
    case TYPES.LOG_IN_PENDING:
    case TYPES.FETCH_USERNAME_PENDING:
    case TYPES.FETCH_LANGUAGES_PENDING:
    case TYPES.FETCH_CHARGE_POINT_GROUPS_PENDING:
    case TYPES.FETCH_CHARGE_POINT_GROUP_PENDING:
    case TYPES.FETCH_CHARGE_POINT_PENDING:
      return {
        ...state,
        isLoading: true
      };

    case TYPES.LOG_IN_SUCCESS:
    case TYPES.LOG_IN_FAILURE:
    case TYPES.FETCH_USERNAME_SUCCESS:
    case TYPES.FETCH_USERNAME_FAILURE:
    case TYPES.FETCH_LANGUAGES_SUCCESS:
    case TYPES.FETCH_LANGUAGES_FAILURE:
    case TYPES.FETCH_CHARGE_POINT_GROUPS_SUCCESS:
    case TYPES.FETCH_CHARGE_POINT_GROUPS_FAILURE:
    case TYPES.FETCH_CHARGE_POINT_GROUP_SUCCESS:
    case TYPES.FETCH_CHARGE_POINT_GROUP_FAILURE:
    case TYPES.FETCH_CHARGE_POINT_SUCCESS:
    case TYPES.FETCH_CHARGE_POINT_FAILURE:
      return {
        ...state,
        isLoading: false
      };
    default:
      return state;
  }
}

import { push } from 'react-router-redux';

import TYPES from './actionTypes';

import { loginAPI, fetchUsernameAPI } from '../../api/auth';
import {
    setJWTTokenToHeader,
    unSetJWTTokenToHeader
} from '../../api/axiosInstance';

export function loginSuccess (dispatch) {
    dispatch(push('/stations'));
    return {
        type: TYPES.LOG_IN_SUCCESS
    };
}

export const logInUser = (credentials) => {
    return async (dispatch) => {
        dispatch({type: TYPES.LOG_IN_PENDING});
        return loginAPI(credentials)
            .then((result) => {
                setJWTTokenToHeader(result.data.token);
                window.localStorage.setItem('JWT_TOKEN', result.data.token);
                dispatch(loginSuccess(dispatch));
            }).catch((error) => {
                dispatch({type: TYPES.LOG_IN_FAILURE});
                if (error.response) {
                    throw error.response.data;
                }
            });
    };
};

export const logOutUser = () => {
    unSetJWTTokenToHeader();
    window.localStorage.removeItem('JWT_TOKEN');
    return {
        type: TYPES.LOG_OUT
    };
};

export const fetchUsername = () => {
    return async (dispatch) => {
        dispatch({type: TYPES.FETCH_USERNAME_PENDING});
        return fetchUsernameAPI()
            .then((result) => {
                dispatch({
                    type: TYPES.FETCH_USERNAME_SUCCESS,
                    payload: result.data
                });
            }).catch((error) => {
                dispatch({type: TYPES.FETCH_USERNAME_FAILURE});
                if (error.response) {
                    throw error.response.data;
                }
            });
    };
};

import styled from 'styled-components'

export const Form = styled.form`
position: relative;
padding: 8px 32px;
min-width: 394px;
width: 30%;
max-height: 100vh;
overflow-x: auto;

-ms-overflow-style: none;
scrollbar-width: none;

&::-webkit-scrollbar { 
display: none;
}

&:before {
position: absolute;
display: block;
content: '';
top: 0;
left: 15px;
width: 1px;
height: ${props => props.progress ? props.progress : '0'}%;
background: #fac715;
transition: height .3s;
}

@media (max-width: 767px) {
max-height: 100%;
}

@media (max-width: 400px) {
padding: 8px;
width: 100%;
min-width: 320px;

&:before {
left: 4px;
}
}
`;

export const Title = styled.h2`
font-size: 22px;
padding-top: 8px;
font-weight: 500;
text-align: left;
color: #000000;
text-transform: uppercase;
margin-bottom: 25px;
`;

export const TitleBlock = styled.div`
min-width: 230px;
width: 70%;
height: 30px;
margin-top: 15px;
display: flex;
align-items: center;
justify-content: space-between;
color: #4a4a4a;
`;

export const ChargeBoxBlock = styled.div`
position: relative;
margin-top: 15px;
padding-bottom: 15px;
border-bottom: 1px solid #c8c8c8;

> .Btn {
position: absolute;
top: 0;
right: 0;
opacity: 0;
transition: opacity .3s;
}

&:hover > .Btn {
opacity: 1;
}
`;

export const ConnectorBlock = styled.div`
margin-top: 15px;
transition: background .3s;
overflow: hidden;

& > div {
> :first-child {
margin-top: 0;
}

> img {
width: auto;
height: 80%;
position: absolute;
bottom: 0;
right: 0;
filter: opacity(0.025);
}

> .Btn {
position: absolute;
top: 0;
right: 0;
opacity: 0;
transition: opacity .3s;
}

&:hover > .Btn {
opacity: 1;
}
}
`;

export const LanguageBlock = styled.div`
position: relative;

& > span {
color: #80807f;
transition: color .3s;

&:after {
position: absolute;
top: 9px;
right: 0px;
display: block;
content: '';
width: calc(100% - 25px);
border-bottom: 1px solid #c8c8c8;
}

&.active {
color: #fac715;
}
}
`;

export const SubmitBtn = styled.button`
min-width: 114px;
border: none;
border-radius: 5px;
background-color: #383838;
font-weight: 100!important;
font-size: 12px!important;
line-height: 1;
padding: 10px 20px 8px!important;
text-transform: uppercase;
box-shadow: none!important;
color: #fff;

&:not(.disabled):hover {
background-color: #000000;
}

&.disabled {
filter: invert(1);
cursor: default;
}
`;

import React from 'react';

import {
  DetailsInfo,
  ImgContainer,
  ImgModal
} from './style.js';

function ImageModal(props) {
  const {photoUrl, pointUpdate, boxUpdate, handleClose} = props;

  return (
    <ImgModal onClick={() => handleClose({})}>
      <ImgContainer>
        <img src={photoUrl.photos[0]} className={pointUpdate[photoUrl.id].online ? 'online' : 'offline'} alt=""/>
        <DetailsInfo>
          {
            photoUrl.charge_boxes.map(cb => (
              <li key={cb.id}>
                {
                  pointUpdate[photoUrl.id].online ?
                    `${cb.conn_current} / ${cb.conn_types} / ${cb.conn_power} kW / ${boxUpdate[cb.id].status_details || boxUpdate[cb.id].status}` :
                    `${cb.conn_current} / ${cb.conn_types} / ${cb.conn_power} kW / unavailable`
                }
              </li>
            ))
          }
        </DetailsInfo>
      </ImgContainer>
    </ImgModal>
  );
}

export default ImageModal;

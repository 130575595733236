import styled from "styled-components";

export const ImgModal = styled.div`
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100vh;
display: flex;
justify-content: center;
align-items: center;
background: rgba(0,0,0,.75);
overflow: hidden;
z-index: 5;
`;

export const ImgContainer = styled.div`
position: relative;
width: 75%;

> img {
display: block;
margin: 0 auto;
max-width: 100%;
height: auto;
max-height: 90vh;
object-fit: contain; 
border-radius: 10px;
transition: box-shadow 1s;

&.online {
box-shadow: 0 0 20px 1px green;
}
&.offline {
box-shadow: 0 0 20px 1px red;
}
}

@media (max-width: 767px) { 
width: 95%;
}
`;

export const DetailsInfo = styled.ul`
position: absolute;
bottom: 10px;
left: 50%;
transform: translate(-50%, 0px);
padding: 10px;
border-radius: 10px;
background: rgba(0,0,0,.5);
max-width: 90%;
overflow: auto;

> li {
color: #ffffff;
white-space: nowrap;
}

@media (max-width: 515px) {
> li {
font-size: 10px;
}
}
`;

import React, {Component} from 'react';
import {isEqual} from 'lodash'
import MainField from '../../components/MainField'
import {validate} from './validate'

import {
  Form,
  Title,
  LanguageBlock,
  SubmitBtn
} from './style.js'

class FormLocation extends Component {
  state = {
    rendering: false,
    name: '',
    location_lat: '',
    location_lng: '',
    defaultLanguage: '',
    languages: [],
    error: {
      name: false,
      location_lat: false,
      location_lng: false,
      defaultLanguage: false,
      languages: [],
    }
  };

  getState = (chargePointGroup) => {
    const {lang} = this.props;
    const newState = {
      languages: []
    };
    const newError = {
      languages: []
    };

    if (chargePointGroup) {
      newState.name = chargePointGroup.name;
      newState.location_lat = chargePointGroup.location_lat;
      newState.location_lng = chargePointGroup.location_lng;
      newState.languages = [...chargePointGroup.languages.map(el => ({...el}))];
      newState.defaultLanguage = chargePointGroup.languages.filter(el => el.default === true)[0].lang;
      for (let language of lang) {
        newError.languages.push({
          lang: language,
          name: false,
          address: false,
          default: false,
        })
      }
      this.setState(prevState => ({
        rendering: true,
        ...newState,
        error: {...prevState.error, ...newError}
      }))
    } else {
      for (let language of lang) {
        newState.languages.push({
          lang: language,
          name: '',
          address: '',
          default: false,
        });
        newError.languages.push({
          lang: language,
          name: false,
          address: false,
          default: false,
        })
      }
      this.setState(prevState => ({
        rendering: true,
        ...newState,
        error: {...prevState.error, ...newError}
      }))
    }
  };

  componentDidMount() {
    this.getState();
  }

  componentDidUpdate(prevProps) {
    const {chargePointGroup, markerPosition, lang} = this.props;

    if (prevProps.lang.length !== lang.length) {
      this.getState()
    }

    if (prevProps.chargePointGroup.id !== chargePointGroup.id) {
      this.getState(chargePointGroup)
    }

    if (!isEqual(prevProps.markerPosition, markerPosition)) {
      this.setState(prevState => ({
        location_lat: markerPosition.lat,
        location_lng: markerPosition.lng,
        error: {
          ...prevState.error,
          location_lat: false,
          location_lng: false,
        }
      }))
    }
  }

  handleChange = (event, index) => {
    event.stopPropagation();
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    if (index >= 0) {
      let newInfoLanguages = [...this.state.languages];
      newInfoLanguages[index][name] = value;
      this.setState({
        languages: [...newInfoLanguages]
      });
    } else {
      this.setState({
        [name]: value
      });
    }
  };

  isValidForm = () => {
    const err = validate(this.state, this.props.lang);
    if (Object.values(this.state).some(el => el === '')) {
      this.setState({
        error: {...err}
      });
      return false
    } else {
      return true
    }
  };

  handlePatch = (event) => {
    const {name, location_lat, location_lng, languages, defaultLanguage} = this.state;
    const {chargePointGroup} = this.props;
    event.stopPropagation();
    event.preventDefault();
    if (this.isValidForm()) {

      const initialValues = {
        name: chargePointGroup.name || '',
        location_lat: chargePointGroup.location_lat || '',
        location_lng: chargePointGroup.location_lng || '',
        languages: chargePointGroup.languages ?
          chargePointGroup.languages.reduce((acc, item) => {
            acc[item['lang']] = {...item};
            return acc
          }, {}) :
          {},
      };

      const updatedValues = {
        name,
        location_lat,
        location_lng,
        languages: languages.map(el => el.lang === defaultLanguage ?
          {...el, default: true}
          : {...el, default: false}).reduce((acc, item) => {
          acc[item['lang']] = {...item};
          return acc
        }, {})
      };

      const formKey = Object.keys(initialValues);

      const fromChanges = formKey.reduce((acc, item) => {
        if (item === 'languages') {
          for (let language of Object.keys(updatedValues[item])) {
            let arrKey = ['lang', 'name', 'address', 'default'];
            for (let key of arrKey) {
              if (initialValues[item][language][key] !== updatedValues[item][language][key]) {
                acc[item] ? acc[item] = {...acc[item]} : acc[item] = {};
                acc[item][language] ? acc[item][language] = {...acc[item][language]} : acc[item][language] = {};
                acc[item][language]['lang'] = language;
                acc[item][language][key] = updatedValues[item][language][key]
              }
            }
          }
        } else {
          if (initialValues[item] !== updatedValues[item]) {
            acc[item] = updatedValues[item]
          }
        }
        return acc
      }, {});


      const newDataLocation = fromChanges.languages ?
        {
          ...fromChanges,
          languages: [...Object.values(fromChanges.languages)]
        } : {...fromChanges};

      this.props.updateCPG(newDataLocation, this.props.chargePointGroup.id);
    }
  };

  sentDataToApi = (event) => {
    event.stopPropagation();
    event.preventDefault();
    if (this.isValidForm()) {
      const newDataLocation = {
        name: this.state.name,
        location_lat: this.state.location_lat,
        location_lng: this.state.location_lng,
        languages: this.state.languages.map(el => el.lang === this.state.defaultLanguage ? {
          ...el,
          default: true
        } : {...el, default: false})
      };

      this.props.createCPG(newDataLocation)
    }
  };

  getProgress = () => {
    const checkArr = [
      'name',
      'location_lat',
      'location_lng',
      'defaultLanguage',
      'languages'
    ];

    let checkedItem = 0;

    for (let key of checkArr) {
      if (key === 'languages') {
        for (let lang of this.state[key]) {
          checkedItem += lang.name !== '' ? 1 : 0;
          checkedItem += lang.address !== '' ? 1 : 0
        }
      } else {
        checkedItem += this.state[key] !== '' ? 1 : 0
      }
    }
    return (checkedItem / (4 + this.state.languages.length * 2)) * 100;
  };

  render() {
    const {rendering, name, location_lat, location_lng, languages, error, defaultLanguage} = this.state;
    const {isLoading, chargePointGroup, lang} = this.props;

    const isEdit = !!chargePointGroup.name;

    const oldInfo = {
      name: chargePointGroup.name ? chargePointGroup.name : '',
      location_lat: chargePointGroup.location_lat ? chargePointGroup.location_lat : '',
      location_lng: chargePointGroup.location_lng ? chargePointGroup.location_lng : '',
      defaultLanguage: chargePointGroup.languages ? chargePointGroup.languages.filter(el => el.default === true)[0].lang : '',
      languages: chargePointGroup.languages ? chargePointGroup.languages : [...lang.map(language => ({
        lang: language,
        name: '',
        address: '',
        default: false
      }))]
    };

    const newInfo = {
      name: name,
      location_lat: location_lat,
      location_lng: location_lng,
      defaultLanguage: defaultLanguage,
      languages: languages
    };

    let isChanged = isEqual(oldInfo, newInfo);

    return (
      (!isLoading && rendering) && <Form autocomplete="off" progress={this.getProgress()}>
        <Title>{isEdit ? `${chargePointGroup.name}` : 'New Location'}</Title>

        <MainField
          name="name"
          type="text"
          value={name}
          onChange={this.handleChange}
          className={error.name ? 'invalid' : ''}
          label="name"
          autoComplete="off"
          error={error.name}
          onFocus={() => this.setState(prevState => ({error: {...prevState.error, name: false}}))}
        />

        <MainField
          name="location_lat"
          type="number"
          value={location_lat}
          //onChange={this.handleChange}
          className={error.location_lat ? 'invalid' : ''}
          label="location lat"
          autoComplete="off"
          readOnly={true}
          placeholder={50.45}
          //step={0.0000001}
          error={error.location_lat}
          onFocus={() => this.setState(prevState => ({error: {...prevState.error, location_lat: false}}))}
        />

        <MainField
          name="location_lng"
          type="number"
          value={location_lng}
          //onChange={this.handleChange}
          className={error.location_lng ? 'invalid' : ''}
          label="location lng"
          autoComplete="off"
          readOnly={true}
          placeholder={30.523718}
          //step={0.0000001}
          error={error.location_lng}
          onFocus={() => this.setState(prevState => ({error: {...prevState.error, location_lng: false}}))}
        />

        {
          languages.map((langInfo, index) => (
            <LanguageBlock key={langInfo.lang} style={{marginTop: 15}}>

              <span className={langInfo.lang === defaultLanguage ? 'active' : ''}>{langInfo.lang}</span>

              <MainField
                name="name"
                type="text"
                value={langInfo.name}
                onChange={(e) => this.handleChange(e, index)}
                className={error.languages.filter(err => err.lang === langInfo.lang)[0].name ? 'invalid' : ''}
                label="name"
                autoComplete="off"
                error={error.languages.filter(err => err.lang === langInfo.lang)[0].name}
                onFocus={() => this.setState(prevState => ({
                  error: {
                    ...prevState.error,
                    languages: prevState.error.languages.map(err => err.lang === langInfo.lang ? {
                      ...err,
                      name: false
                    } : err)
                  }
                }))}
              />

              <MainField
                name="address"
                type="text"
                value={langInfo.address}
                onChange={e => this.handleChange(e, index)}
                className={error.languages.filter(err => err.lang === langInfo.lang)[0].address ? 'invalid' : ''}
                label="address"
                autoComplete="off"
                error={error.languages.filter(err => err.lang === langInfo.lang)[0].address}
                onFocus={() => this.setState(prevState => ({
                  error: {
                    ...prevState.error,
                    languages: prevState.error.languages.map(err => err.lang === langInfo.lang ? {
                      ...err,
                      address: false
                    } : err)
                  }
                }))}
              />

              <MainField
                name="defaultLanguage"
                type="radio"
                id={langInfo.lang}
                value={langInfo.lang}
                onChange={this.handleChange}
                checked={defaultLanguage === langInfo.lang}
                className={`radio ${error.defaultLanguage ? 'invalid' : ''}`}
                label="default lang"
                autoComplete="off"
                width='auto'
                error={error.defaultLanguage}
                onFocus={() => this.setState(prevState => ({error: {...prevState.error, defaultLanguage: false}}))}
              />

            </LanguageBlock>
          ))
        }

        <div style={{marginTop: 50}}>
          <SubmitBtn className={isEdit ?
            isChanged ? 'disabled' : '' :
            this.getProgress() === 100 ? '' : 'disabled'
          }
                     disabled={isEdit ?
                       isChanged :
                       this.getProgress() !== 100
                     }
                     type="onSubmit"
                     onClick={isEdit ? this.handlePatch : this.sentDataToApi}
          >
            {isEdit ? 'SAVE CHANGES' : 'SAVE'}
          </SubmitBtn>
        </div>
      </Form>
    )
  }
}

export default FormLocation

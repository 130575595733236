import React from 'react';

import {
  Wrapper
} from './style.js';

function Loader(props) {
    const {local} = props;
    return (
        <Wrapper className={local ? 'local' : '' }>
            <div className="loader"/>
        </Wrapper>
    );
}

export default Loader

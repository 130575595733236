import {connect} from 'react-redux';

import {fetchLanguages} from '../../store/actions/languagesActions';

import CreateNewStation from './CreateNewStation';
import {clearChargePoint, fetchGetChargePoint, clearChargePointMessage} from "../../store/actions/chargePointActions";
import {fetchChargePointGroups} from "../../store/actions/chargePointGroupsActions";

const mapStateToProps = (state) => ({
  isLoading: state.app.isLoading,
  lang: state.entities.languages,
  chargePointGroups: state.entities.chargePointGroups,
  chargePoint: state.entities.chargePoint,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getLanguages: () => {
      return dispatch(fetchLanguages());
    },
    getChargePointGroups: () => {
      return dispatch(fetchChargePointGroups());
    },
   getStation: (id) => {
      dispatch(fetchGetChargePoint(id))
    },
    clearCP: () => {
      return dispatch(clearChargePoint());
    },
    clearMessage: () => {
      return dispatch(clearChargePointMessage())
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateNewStation);

import styled from 'styled-components'

export const Button = styled.button`
position: relative;
z-index: 2;
border: ${(props) => props.withOutBorder ? 'none' : `1px solid ${props.color}`};
outline: none;
border-radius: 50%;
width: 24px;
height: 24px;
cursor: pointer;
transition: transform .4s cubic-bezier(.21,1.06,.93,.86);

&:before, :after{
position: absolute;
display: block;
content: '';
top: 50%;
left: 50%;
transform: translate(-50%,-50%) ${(props) => props.cross ? 'rotate(45deg)' : ''};
width: 12px;
height: 1px;
border-radius: .5px;
transition: height .4s;
background: ${(props) => props.color};
}

&:after{
width: 1px;
height: ${(props) => props.del ? 0 : 12}px;
}

&:hover{
transform: rotate(360deg)
}
`;
